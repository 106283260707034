import React from 'react';
import { Grid, Card, CardActions, Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Button } from 'common/components';
import { UseFormikResponse } from 'common/interfaces/formik.interface';
import { useTranslation } from 'react-i18next';
import { ProfileLgpdLegalBasisFormValues } from './profile-lgpd-legal-basis';

interface ProfileLgpdLegalBasisViewProps {
    form: UseFormikResponse<ProfileLgpdLegalBasisFormValues>;
}

const ProfileLgpdLegalBasisView: React.FC<ProfileLgpdLegalBasisViewProps> = ({ form }) => {
    const { t } = useTranslation();

    return (
        <Card>
            <form onSubmit={form.handleSubmit} noValidate>
                <Grid container direction='column' spacing={2}>
                    <Grid item>
                        <Typography variant='h6'>{t('title.privacy-and-preferences')}</Typography>
                    </Grid>

                    <Grid item xs={12} style={{ marginLeft: 8 }}>
                        <FormControlLabel
                            label={t(
                                'auth.profile.profile-lgpd-legal-basis.allow-send-process-opportunities-by-email'
                            )}
                            name='allowSendProcessOpportunitiesByEmail'
                            onChange={form.handleChange}
                            control={
                                <Checkbox
                                    checked={form?.values?.allowSendProcessOpportunitiesByEmail}
                                    color='primary'
                                />
                            }
                        />
                    </Grid>

                    <CardActions style={{ padding: 8, justifyContent: 'flex-end' }}>
                        <Button
                            type='submit'
                            size='small'
                            variant='contained'
                            disabled={form.isSubmitting}
                        >
                            {t('term.save')}
                        </Button>
                    </CardActions>
                </Grid>
            </form>
        </Card>
    );
};

export default ProfileLgpdLegalBasisView;

import { FC } from 'react';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { AuctionLotFormValues } from 'modules/process/context/lots.form';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { lotActions, processActions, processValidations } from 'modules/process/process-actions';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import ProcessLotHeaderView from './process-lot-header-view';

export interface ProcessLotHeaderProps {
    expandedLot: boolean;
    onExpandLot: (expanded: boolean) => void;
    onClickDeleteLot: () => void;
    onClickAdvancedInfo: () => void;
    lotItem: number;
    lotHasChanged: boolean;
    visibleAdvancedInfoBlock: boolean;
    lot: AuctionLotFormValues;
}

const ProcessLotHeader: FC<ProcessLotHeaderProps> = ({ lot, lotItem, ...props }) => {
    const { processForm } = useProcessFormContext();
    const { processLotsForm } = useProcessLotsFormContext();

    const foundLotIndexById = processLotsForm?.values?.lots?.findIndex(
        (formLot) => formLot.id === lot?.id
    );
    const foundLotIndexByItem = processLotsForm?.values?.lots?.findIndex(
        (formLot) => formLot.item === lot?.item
    );

    const thereIsLotQuota = processLotsForm?.values?.lots?.some((lot) => lot.quotaId);

    const canDeleteLotWithThisQuantity = () => {
        if (
            processForm &&
            processActions.inDraft(processForm?.values?.biddingStageId ?? BiddingStageEnum.draft)
        )
            return true;

        const lotsQuantity = processLotsForm.values.lots.length;
        return thereIsLotQuota ? lotsQuantity > 2 : lotsQuantity > 1;
    };

    const canEditLotQuota = lotActions.canEditLotQuota(lot, processForm?.values);
    const canEditLot = processActions.canEditLot(processForm?.values.biddingStageId) && canEditLotQuota;
    const lotItemIdentifier = lot.item;
    const isImportedLot = !processValidations.isLotsFromImport(processForm?.values);

    const isLotValid =
        Object.keys(
            processLotsForm.errors?.lots?.[lot.id ? foundLotIndexById : foundLotIndexByItem] ?? {}
        ).length === 0;

    const canDeleteLot =
        processActions.canDeleteLot(processForm?.values) &&
        !lot.quotaId &&
        canDeleteLotWithThisQuantity();

    return (
        <ProcessLotHeaderView
            {...{
                ...props,
                lot,
                lotItem,
                processLotsForm,
                lotItemIdentifier,
                isImportedLot,
                isLotValid,
                canDeleteLot,
                canEditLot,
            }}
        />
    );
};

export default ProcessLotHeader;

import { Grid, makeStyles, MenuItem, Paper, Typography } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { TextField } from 'common/components';
import { providerSignIn, useIdentityProviders } from 'common/amplify';
import { usePlatformContext } from 'routes/platform.context';
import { Link } from 'react-router-dom';
import LoadingButton from 'common/components/loading-button';
import { useTranslation } from 'react-i18next';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { LogoContainer, NewTypography, RegisterLink, Row, SocialBtn } from './styles';

const useStyles = makeStyles(() => ({
    root: {
        height: '100vh;',
        backgroundColor: '#f2f2f2',
    },
    paper: {
        padding: '3rem',
        borderRadius: '1rem',
        width: '28rem',
        margin: '1rem',
    },
}));

export const UserAuthView = ({ form }) => {
    const classes = useStyles();
    const { identityProviders } = useIdentityProviders();
    const { platform } = usePlatformContext();
    const defaultImageLogo = platform?.urlOriginalLogo;
    const { t } = useTranslation();

    const { IBGEcountries } = useIBGECountries();

    const enabledIdentityProviders = identityProviders.filter((provider) => provider.enabled);

    return (
        <Grid container justifyContent='center' alignItems='center' className={classes.root}>
            <Grid item>
                <LogoContainer>
                    <img src={defaultImageLogo} alt='logo' />
                </LogoContainer>
                <Paper className={classes.paper}>
                    <form onSubmit={form.handleSubmit} noValidate>
                        <Grid container direction='column' spacing={2}>
                            <Grid item>
                                <Grid container direction='row' spacing={2}>
                                    <Grid item xs={8}>
                                        <IdentificationNumberTextField
                                            identificationNumber={form.values.cpf}
                                            country={form.values.country}
                                            fieldName='cpf'
                                            type='cpf'
                                            {...form}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            select
                                            label={t('term.country')}
                                            fullWidth
                                            name='country'
                                            value={form.values.country}
                                            onChange={form.handleChange}
                                            error={form.touched.country && !!form.errors.country}
                                            helperText={form.touched.country && form.errors.country}
                                        >
                                            {IBGEcountries.map(({ code, name }) => (
                                                <MenuItem key={code} value={code}>
                                                    {name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    type='password'
                                    fullWidth
                                    label={t('term.password')}
                                    name='password'
                                    value={form.values.password}
                                    onChange={form.handleChange}
                                    error={form.touched.password && !!form.errors.password}
                                    helperText={form.touched.password && form.errors.password}
                                />
                            </Grid>
                            <Grid item>
                                <LoadingButton
                                    type='submit'
                                    fullWidth
                                    variant='contained'
                                    color='primary'
                                    disabled={form.isSubmitting}
                                    {...(form.isSubmitting && { loading: { text: '' } })}
                                >
                                    {t('auth.general.sign-in')}
                                </LoadingButton>
                            </Grid>

                            <Grid item>
                                <Typography variant='subtitle1'>
                                    <RegisterLink to='/recuperar-senha'>
                                        {t('auth.sign-forgot-password')}
                                    </RegisterLink>
                                </Typography>
                            </Grid>

                            <Row>
                                <Divider />
                                <Typography variant='caption'>{t('auth.sign-methods')}</Typography>
                                <Divider />
                            </Row>

                            <Grid container alignContent='center' justifyContent='center'>
                                {enabledIdentityProviders.map(({ name, icon }) => (
                                    <SocialBtn key={name} onClick={() => providerSignIn(name)}>
                                        {icon}
                                    </SocialBtn>
                                ))}
                            </Grid>
                            <Grid item>
                                <NewTypography variant='subtitle1' align='center'>
                                    {t('auth.sign-up-link')}{' '}
                                    <Link to='/cadastro'>{t('auth.general.sign-up')}</Link>
                                </NewTypography>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    );
};

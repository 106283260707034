import React, { FC, useState } from 'react';
import ReactDOM from 'react-dom';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { Button } from 'common/components';
import { useTranslation } from 'react-i18next';
import { diff } from 'deep-object-diff';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { auctionNoticeLotRequests } from 'clients/manager/auction-notice-lot.requests';
import LoadingButton from 'common/components/loading-button';
import {
    addNotificationApiError,
    addNotificationError,
    addNotificationSuccess,
} from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { AuctionLotWithItemsForm } from 'modules/process/context/lots.form';
import {
    AuctionNoticeLotWithItems,
    CreateProcessLot,
    ProcessLot,
    TypeOfBenefitValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import LoadingModal from 'common/components/modal-loading';
import ModalImportExport from '../modal-import-export';
import ModalBulkChangesLots from '../modal-bulk-changes-lots';
import { ProcessForm, useProcessFormContext } from '../../../../context/process-form.context';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';

interface ProcessLotsActionsProps {
    handleDefaultPage: (newValues: AuctionLotWithItemsForm['lots']) => void;
}

const ProcessLotsActions: FC<ProcessLotsActionsProps> = ({ handleDefaultPage }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isVisibleModalImport, setIsVisibleModalImport] = useState(false);
    const [isVisibleModalBulkChanges, setIsVisibleModalBulkChanges] = useState(false);
    const [savingLotChanges, setSavingLotChanges] = useState(false);
    const { t } = useTranslation();
    const { process } = useProcessFormContext();
    const {
        processLotsForm,
        formHasChanges,
        processLotsBkpBeforeSave,
        setProcessLotsBkpBeforeSave,
    } = useProcessLotsFormContext();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    const handleClickBulkChanges = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            handleClose();
            setIsVisibleModalBulkChanges(true);
        });
    };

    const handleClickImportLots = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            handleClose();
            setIsVisibleModalImport(true);
        });
    };

    const getLotsValuesDifferences = (): ProcessLot[] => {
        const differenceLotValues: ProcessLot[] = [];

        processLotsForm?.values?.lots?.forEach((lotForm) => {
            let diffs;

            if (!lotForm.id) {
                differenceLotValues.push(lotForm as any);
            } else {
                processLotsBkpBeforeSave?.lots?.forEach((bkpLot) => {
                    if (lotForm.id && bkpLot.id === lotForm.id) {
                        diffs = diff(bkpLot, lotForm);

                        if (Object.keys(diffs).length > 0) {
                            differenceLotValues.push(lotForm as any);
                        }
                    }

                    if (!lotForm.id && bkpLot.item === lotForm.item) {
                        diffs = diff(bkpLot, lotForm);

                        if (Object.keys(diffs).length > 0) {
                            differenceLotValues.push(lotForm as any);
                        }
                    }
                });
            }
        });

        return differenceLotValues;
    };

    const fetchLotData = (lot: ProcessLot, process: ProcessForm): ProcessLot => {
        return {
            auctionNoticeId: process.id,
            id: lot.id,
            bidAmountDifference: lot.bidAmountDifference,
            lotDescription: lot.lotDescription,
            markIsRequired: Boolean(lot.markIsRequired),
            showReferenceValue: Boolean(lot.showReferenceValue),
            typeOfBenefit: lot.typeOfBenefit,
            ruleRestrictBySegments: lot.ruleRestrictBySegments,
            listRestrictSegments: lot.listRestrictSegments,
            typeOfItems: lot.typeOfItems,
            itemsKits: lot.itemsKits,
            quotaId: lot.quotaId,
            item: lot.item as number,
            requireDocuments: Boolean(lot.requireDocuments),
            forceSameDescription:
                [AuctionType.accreditation, AuctionType.publicSale].includes(process.auctionType) ||
                Boolean(lot.forceSameDescription),
            propertyOrFurnitureCode: lot.propertyOrFurnitureCode,
            allowMultipleWinner: !!lot.allowMultipleWinner,
            multipleWinners: lot.multipleWinners,
            items:
                lot?.items?.map((item) => ({
                    id: item.id,
                    auctionNoticeId: process.id,
                    lotId: lot.id,
                    item: item.item,
                    externalItemId: item?.externalItemId,
                    itemDescription: item.itemDescription,
                    unitMeasurement: item.unitMeasurement,
                    amount: item.amount,
                    referenceValue: item.referenceValue,
                    susCode: item.susCode,
                    susText: item.susText,
                })) ?? [],
        };
    };

    const separateLotsToUpdateAndToCreate = (
        differenceLotValues: ProcessLot[],
        process: ProcessForm
    ) => {
        const lotsToUpdate: ProcessLot[] = [];
        const lotsToCreate: ProcessLot[] = [];

        differenceLotValues.forEach((lot) => {
            const processLot = fetchLotData(lot, process);

            if (!lot.id) {
                lotsToCreate.push(processLot);
            } else {
                lotsToUpdate.push(processLot);
            }
        });

        return { lotsToUpdate, lotsToCreate };
    };

    const getNewLotData = (lot: ProcessLot): CreateProcessLot => {
        const newLot = {
            ...lot,
            item: lot.item as number,
            auctionNoticeId: lot.auctionNoticeId,
        };

        return newLot;
    };

    const updateLotDataAfterChange = async (processId: number) => {
        const response = await auctionNoticeLotRequests.listLotsFromProcess({
            params: {
                auctionId: processId,
            },
        });

        const lotsAfterChange = { lots: response.data };
        processLotsForm.setValues(lotsAfterChange);
        setProcessLotsBkpBeforeSave?.(lotsAfterChange);
    };

    const findLotBkpByItem = (item: number): AuctionNoticeLotWithItems | undefined => {
        if (!processLotsBkpBeforeSave) return undefined;
        return processLotsBkpBeforeSave.lots.find((lot) => lot.item === item && !lot.quotaId);
    };

    const findLotFormByItem = (item: number): AuctionNoticeLotWithItems | undefined => {
        return processLotsForm.values.lots.find((lot) => lot.item === item && !lot.quotaId);
    };

    const verifyCanCreateLots = (lots: ProcessLot[]): boolean => {
        const canCreate = lots.every((lot) => {
            if (lot.quotaId === null && lot.item) {
                const lotForm = findLotFormByItem(lot.item);
                if (lotForm?.typeOfBenefit === TypeOfBenefitValue.masterQuota) {
                    const lotItemAmount = lot?.items ? lot?.items[0].amount : null;
                    if (lotItemAmount && lotItemAmount < 4) {
                        return false;
                    }
                }
            }
            return true;
        });

        return canCreate;
    };

    const verifyCanUpdateLots = (lots: ProcessLot[]): boolean => {
        const canUpdate = lots.every((lot) => {
            if (!lot.quotaId && lot.item && lot?.items) {
                const lotForm = findLotBkpByItem(lot.item);
                if (lotForm?.typeOfBenefit === TypeOfBenefitValue.masterQuota) {
                    const lotItemAmount = lotForm?.items[0].amount;
                    const lotItemAmountUpdated = lot?.items[0]?.amount;
                    if (
                        lotItemAmountUpdated &&
                        lotItemAmount !== lotItemAmountUpdated &&
                        lotItemAmountUpdated < 4
                    ) {
                        return false;
                    }
                }

                if (
                    lot.typeOfBenefit === TypeOfBenefitValue.masterQuota &&
                    lotForm?.typeOfBenefit !== TypeOfBenefitValue.masterQuota
                ) {
                    const lotItemAmountUpdated = lot?.items[0]?.amount;
                    if (lotItemAmountUpdated && lotItemAmountUpdated < 4) {
                        return false;
                    }
                }
            }
            return true;
        });

        return canUpdate;
    };

    const handleLotCreation = async (lotsToCreate: ProcessLot[]) => {
        const createdLotsPromises = lotsToCreate.map(async (lot) => {
            const newLot = getNewLotData(lot);
            const response = await auctionNoticeLotRequests.createProcessLot(newLot);

            if (!response?.data?.[0]?.id) {
                throw new Error(t('process.components.error-save-lot'));
            }

            if (response?.data?.[1] !== null && !response?.data?.[1].id) {
                throw new Error(t('process.components.error-save-lot'));
            }

            return response.data;
        });

        await Promise.all(createdLotsPromises);
    };

    const handleLotUpdate = async (lotsToUpdate: ProcessLot[], processId: number) => {
        const response = await auctionNoticeLotRequests.updateProcessLot({
            auctionNoticeId: processId,
            lots: lotsToUpdate,
        });

        if (response.status !== 'success' || !response.data || response.data.length === 0) {
            throw new Error(t('process.components.error-update-lot'));
        }
    };

    const onClickSaveChangesLots = async () => {
        if (!process?.id) return;

        try {
            setSavingLotChanges(true);
            const differenceLotValues: ProcessLot[] = getLotsValuesDifferences();
            const { lotsToUpdate, lotsToCreate } = separateLotsToUpdateAndToCreate(
                differenceLotValues,
                process
            );

            const canCreate = verifyCanCreateLots(lotsToCreate);
            const canUpdate = verifyCanUpdateLots(lotsToUpdate);

            if (!canCreate || !canUpdate) {
                return addNotificationError({
                    message: t('info.amount-invalid'),
                });
            }

            if (lotsToCreate.length > 0) {
                await handleLotCreation(lotsToCreate);
            }

            if (lotsToUpdate.length > 0) {
                await handleLotUpdate(lotsToUpdate, process.id);
            }

            await updateLotDataAfterChange(process.id);
            addNotificationSuccess();
        } catch (error) {
            addNotificationApiError(error);
        } finally {
            setSavingLotChanges(false);
        }
    };

    const lotsCount = processLotsForm.values.lots.length;

    return (
        <>
            {isVisibleModalImport && process && (
                <ModalImportExport
                    type='import'
                    auctionId={process.id}
                    onClose={() => setIsVisibleModalImport(false)}
                    handleDefaultPage={handleDefaultPage}
                />
            )}
            {isVisibleModalBulkChanges && process && (
                <ModalBulkChangesLots
                    auctionId={process.id}
                    onClose={() => setIsVisibleModalBulkChanges(false)}
                    handleDefaultPage={handleDefaultPage}
                />
            )}

            <Box width={1} height={1} display='flex' justifyContent='flex-end' alignItems='center'>
                <LoadingModal open={savingLotChanges} message={t('info.wait-updating-lot')} />
                <LoadingButton
                    disabled={
                        !formHasChanges ||
                        processActions.cannotEditUntilDispute(process) ||
                        !!Object.keys(processLotsForm.errors).length
                    }
                    onClick={onClickSaveChangesLots}
                    color='primary'
                    size='xsmall'
                    variant='contained'
                    style={{
                        marginLeft: '8px',
                    }}
                    {...(savingLotChanges
                        ? {
                              loading: {
                                  text: `${t('term.updating')}..`,
                              },
                          }
                        : {})}
                >
                    {t('process.save-batches')}
                </LoadingButton>
                <Button
                    aria-controls='actions'
                    aria-haspopup='true'
                    aria-expanded={anchorEl ? 'true' : undefined}
                    onClick={handleClick}
                    color='secondary'
                    size='xsmall'
                    variant='contained'
                    style={{
                        marginLeft: '8px',
                    }}
                >
                    <MoreVertIcon /> {t('term.actions')}
                </Button>
                <Menu
                    id='simple-menu'
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={handleClose}
                >
                    <MenuItem
                        disabled={!processActions.canEditLot(process?.biddingStageId)}
                        onClick={handleClickImportLots}
                    >
                        {t('process.components.import-lot-itens')}
                    </MenuItem>
                    <MenuItem
                        disabled={!lotsCount || processActions.cannotEditUntilDispute(process)}
                        onClick={handleClickBulkChanges}
                    >
                        {t('process.components.bulk-changes')}
                    </MenuItem>
                </Menu>
            </Box>
        </>
    );
};

export default ProcessLotsActions;

import { userRequests } from 'clients/manager/user.requests';
import { addNotificationError, addNotificationSuccess } from 'common/utils';
import { useFormik } from 'formik';
import YupValidator from 'common/utils/yup-extended.utils';
import i18n from 'i18next';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { setAuthUser } from 'store/user/user.actions';
import { LgpdLegalBasis } from 'common/interfaces/lgpd-legal-basis.interface';
import ProfileLgpdLegalBasisView from './profile-lgpd-legal-basis-view';

export interface ProfileLgpdLegalBasisFormValues {
    allowSendProcessOpportunitiesByEmail: boolean;
}

const ProfileLgpdLegalBasis = () => {
    const dispatch = useDispatch();

    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const lgpdLegalBasis: LgpdLegalBasis = authUser?.lgpdLegalBasis as any;

    const initialValues = {
        allowSendProcessOpportunitiesByEmail:
            lgpdLegalBasis?.optional?.allowSendProcessOpportunitiesByEmail ?? false,
    };

    const validationSchema = YupValidator.object().shape({
        allowSendProcessOpportunitiesByEmail: YupValidator.boolean(),
    });

    const onSubmit = async (values: ProfileLgpdLegalBasisFormValues, { setSubmitting }) => {
        try {
            const user = await userRequests.updateLgpdLegalBasis(values);

            if (user) {
                i18n.changeLanguage(user.language);
                dispatch(setAuthUser({ user }));
            }
            addNotificationSuccess();
        } catch {
            addNotificationError();
        } finally {
            setSubmitting(false);
        }
    };

    const form = useFormik({ initialValues, validationSchema, onSubmit });

    return <ProfileLgpdLegalBasisView {...{ form }} />;
};

export default ProfileLgpdLegalBasis;

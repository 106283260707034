import { MultipleWinners } from 'modules/process/components/process-data/components/multiple-winners/props';
import { ProcessLotItem } from '../auction-lot-item.requests';

enum LotStatus {
    paused = 'paused',
    suspended = 'suspended',
    finished = 'finished',
    open_period = 'open_period',
    close_period = 'close_period',
    second_close_period = 'second_close_period',
    canceled = 'canceled',
    negotiation = 'negotiation',
    negotiation_finished = 'negotiation_finished',
    no_winner_finished = 'no_winner_finished',
    awaiting_rejudge = 'awaiting_rejudge',
    awaiting_call_provider = 'awaiting_call_provider',
    awaiting_repeat_close_period = 'awaiting_repeat_close_period',
    repeat_second_close_period = 'repeat_second_close_period',
}

enum TypeOfItemsValue {
    product = 'product',
    service = 'service',
    properties = 'properties',
    furniture = 'furniture',
}

enum LotStageValue {
    dispute = 'dispute',
    enabled = 'enabled',
    adjudication = 'adjudication',
    appeal = 'appeal',
    homologation = 'homologation',
    contract = 'contract',
}

enum TypeOfBenefitValue {
    noBenefit = 'noBenefit',
    isExclusive = 'isExclusive',
    subcontracting = 'subcontracting',
    reservedQuota = 'reservedQuota',
    masterQuota = 'masterCota',
    doNotChange = -1,
}

enum RuleRestrictSegments {
    free = 'free',
    and = 'and',
    or = 'or',
}

enum LotDesert {
    isDesert = 1,
    notDesert = 0,
}

enum LotFrustated {
    isFrustrated = 1,
    notFrustrated = 0,
}

interface AuctionNoticeLotItem {
    id: number;
    auctionNoticeId: number;
    lotId: number;
    item: number;
    externalItemId: string;
    itemDescription: string;
    unitMeasurement: string;
    amount: number;
    attachDatasheet: number;
    referenceValue: number;
    specificDocuments: string;
    dateTimeInsert: Date;
    dateTimeUpdate: Date;
    pncpLink?: string;
    susCode?: string;
    susText?: string;
}

interface AuctionNoticeLotWithItems {
    id: number;
    auctionNoticeId: number;
    winnerProviderId: number;
    quotaId?: number;
    isQuota: number;
    item: number;
    externalItemId: string;
    typeOfItems: TypeOfItemsValue;
    isExclusiveToSimpleCompany: number;
    markIsRequired: number;
    openProposals: number;
    lotDescription: string;
    status: LotStatus;
    lotStage: LotStageValue;
    typeOfBenefit: TypeOfBenefitValue;
    bidAmountDifference: number;
    globalValueFinal: number;
    showReferenceValue: number;
    referenceValue: number;
    justificationHideValueReferences: string;
    bidStartDate?: Date;
    bidRenewDate?: Date;
    lastBidDate?: Date;
    extensionTime?: Date;
    dateTimeInsert: Date;
    dateTimeUpdate: Date;
    adjudicationUserId: number;
    adjudicationDateTime: Date;
    homologationUserId: number;
    homologationDateTime: Date;
    isItFrustrated: number;
    frustratedDataTime: Date;
    frustratedUserId: number;
    isItDesert: number;
    desertedUserId: number;
    itemsKits: number;
    desertedDateTime: Date;
    items: AuctionNoticeLotItem[];
    valueReference: number;
    requireDocuments: number;
    quota?: AuctionNoticeLotWithItems;
    ruleRestrictBySegments?: RuleRestrictSegments;
    listRestrictSegments?: string;
    forceSameDescription?: number;
    propertyOrFurnitureCode?: string;
    allowMultipleWinner: boolean;
    multipleWinners: MultipleWinners[];
}

interface AuctionNoticeLotPartialItems {
    id: number;
    auctionNoticeId: number;
    item: number;
    lotDescription: string;
    referenceValue: number;
    bidAmountDifference: number;
    showReferenceValue: number;
    winnerProviderId: number;
    lotStage: LotStageValue;
    status: LotStatus;
    isItDesert: number;
    isItFrustrated: number;
}

interface ListLotsFromProcessFilters {
    limit?: number;
    offset?: number;
    params: {
        auctionId: number;
        auctionLotIds?: number[];
    };
}

interface DeleteProcessLot {
    auctionId: number;
    auctionLotId: number;
}

interface UpdateProcessLotQuota extends DeleteProcessLot {
    typeOfBenefit: string;
}

interface UpdateLotQuotaResponse {
    updatedLotItems: AuctionNoticeLotItem[];
}

interface BulkLotsUpdate {
    auctionNoticeId: number;
    bidAmountDifference?: number;
    typeOfItems?: TypeOfItemsValue;
    markIsRequired?: boolean;
    showReferenceValue?: boolean;
    typeOfBenefit?: TypeOfBenefitValue;
    requireDocuments?: boolean;
    forceSameDescription?: boolean;
    propertyOrFurnitureCode?: string;
}

interface CreateProcessLot {
    markIsRequired?: boolean;
    typeOfItems?: TypeOfItemsValue;
    lotDescription?: string;
    bidAmountDifference?: number;
    typeOfBenefit?: TypeOfBenefitValue;
    showReferenceValue?: boolean;
    justificationHideValueReferences?: string;
    itemsKits?: number;
    item: number;
    quotaId?: number;
    isQuota?: number;
    referenceValue?: number;
    valueReference?: number;
    requireDocuments?: boolean;
    ruleRestrictBySegments?: RuleRestrictSegments;
    listRestrictSegments?: string;
    forceSameDescription?: boolean;
    propertyOrFurnitureCode?: string;
}

interface CreateProcessLotQuota {
    auctionNoticeId: number;
    auctionLotId: number;
}

interface ProcessLot extends Omit<CreateProcessLot, 'item'> {
    auctionNoticeId: number;
    id: number;
    items?: ProcessLotItem[];
    item?: number;
    allowMultipleWinner?: boolean;
    multipleWinners?: {
        position: number;
        percent: string;
    }[];
}

interface UpdateProcessLot {
    auctionNoticeId: number;
    lots: ProcessLot[];
}

export type {
    AuctionNoticeLotWithItems,
    AuctionNoticeLotItem,
    AuctionNoticeLotPartialItems,
    ListLotsFromProcessFilters,
    UpdateProcessLotQuota,
    UpdateLotQuotaResponse,
    DeleteProcessLot,
    BulkLotsUpdate,
    CreateProcessLot,
    CreateProcessLotQuota,
    ProcessLot,
    UpdateProcessLot,
};

export {
    LotStatus,
    TypeOfItemsValue,
    LotStageValue,
    TypeOfBenefitValue,
    RuleRestrictSegments,
    LotDesert,
    LotFrustated,
};

import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { addressRequest } from 'clients/manager/address.requests';
import { DataViaCep } from 'common/interfaces/via-cep-data';
import { Typography } from '@material-ui/core';
import { Button, MaskedTextField, TextField } from 'common/components';
import { FormikErrors, useFormik } from 'formik';
import { FocusEvent, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SignUpProviderContext } from 'common/components/base/pages/context/SignUpProviderContext';
import * as yup from 'yup';
import { CONSTANTS } from 'common/constants';

const Address = ({ loading, setLoading }) => {
    const {
        provider,
        setProvider,
        errorMessage,
        cnpjWsProviderInfo,
        hasFoundedCnpjWsInfo,
        loading: loadingProviderContext,
    } = useContext(SignUpProviderContext);
    const [dataViaCep, setDataViaCep] = useState<DataViaCep>();
    const [errorSearchCEP, setErrorSearchCEP] = useState(false);
    const [errorLenghtCEP, setErrorLenghtCEP] = useState(false);

    const { t } = useTranslation();

    const initialValues = {
        zipCode: cnpjWsProviderInfo?.zipCode ?? '',
        publicPlace: cnpjWsProviderInfo?.street ?? '',
        numberPlace: cnpjWsProviderInfo?.numberPlace ?? '',
        complementPlace: cnpjWsProviderInfo?.complementPlace ?? '',
        district: cnpjWsProviderInfo?.district ?? '',
        state: cnpjWsProviderInfo?.state ?? '',
        cityName: cnpjWsProviderInfo?.city ?? '',
        ibgeCode: cnpjWsProviderInfo?.ibgecode ?? '',
    };

    const validationSchema = yup.object({
        zipCode: yup.string().required(t('please-fill-in-the-cep', { ns: 'validation' })),
        publicPlace: yup.string().required(t('address', { ns: 'validation' })),
        numberPlace: yup.string().required(t('number', { ns: 'validation' })),
        district: yup.string().required(t('district', { ns: 'validation' })),
        state: yup.string().required(t('state', { ns: 'validation' })),
        cityName: yup.string().required(t('city', { ns: 'validation' })),
    });

    const onSubmit = (values) => {
        setProvider((prevProvider: any) => ({
            ...prevProvider,
            ...values,
            zipCode: values.zipCode.replaceAll('-', '').replaceAll('_', ''),
        }));
    };

    const formStep2 = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });

    const onBlurCep = async (
        e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        setFieldValue: {
            (field: string, value: any, shouldValidate?: boolean | undefined):
                | Promise<void>
                | Promise<
                      FormikErrors<{
                          docNumber: string;
                          companyName: string;
                          tradingName: string;
                          zipCode: string;
                          publicPlace: string;
                          numberPlace: string;
                          complementPlace: string;
                          district: string;
                          state: string;
                          cityName: string;
                          ibgecode: string | null;
                          country: string;
                      }>
                  >;
            (arg0: string, arg1: any): void;
        }
    ) => {
        if (provider?.country !== CONSTANTS.ibgeCodes.BRAZIL) {
            setFieldValue('ibgeCode', null);
            return;
        }
        const cepValue = e.target.value;
        const cep = cepValue?.replace(/[^0-9]/g, '');
        if (cep.length >= 8) {
            try {
                setLoading(true);
                setErrorLenghtCEP(false);
                const data = await addressRequest.getAddress(cep);
                setDataViaCep(data);

                if (!data.localidade) {
                    setErrorSearchCEP(true);
                } else {
                    setErrorSearchCEP(false);
                }

                setFieldValue('cityName', data.localidade);
                setFieldValue('state', data.uf);
                setFieldValue('ibgeCode', data.ibge);

                if (data.logradouro === '') {
                    document.getElementById('publicPlace')?.focus();
                } else {
                    setFieldValue('publicPlace', data.logradouro);
                    setFieldValue('district', data.bairro);
                    document.getElementById('numberPlace')?.focus();
                }
            } catch (error) {
                setErrorSearchCEP(true);
            } finally {
                setLoading(false);
            }
        } else {
            setErrorLenghtCEP(true);
        }
    };

    return (
        <Grid container>
            <form onSubmit={formStep2.handleSubmit}>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={12} xs={12}>
                        {provider?.country === CONSTANTS.ibgeCodes.BRAZIL ? (
                            <MaskedTextField
                                mask='99999-999'
                                fullWidth
                                label='CEP'
                                name='zipCode'
                                value={formStep2.values.zipCode}
                                disabled={
                                    hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.zipCode)
                                }
                                onChange={formStep2.handleChange}
                                error={
                                    formStep2.touched.zipCode && Boolean(formStep2.errors.zipCode)
                                }
                                helperText={formStep2.touched.zipCode && formStep2.errors.zipCode}
                                onBlur={(e) => onBlurCep(e, formStep2.setFieldValue)}
                                InputProps={{
                                    endAdornment: loading && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                label='CEP'
                                name='zipCode'
                                disabled={
                                    hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.zipCode)
                                }
                                value={formStep2.values.zipCode}
                                onChange={formStep2.handleChange}
                                onBlur={(e) => onBlurCep(e, formStep2.setFieldValue)}
                                error={
                                    formStep2.touched.zipCode && Boolean(formStep2.errors.zipCode)
                                }
                                helperText={formStep2.touched.zipCode && formStep2.errors.zipCode}
                            />
                        )}
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={
                                (hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.street)) ||
                                Boolean(dataViaCep?.logradouro && true)
                            }
                            label={t('term.address')}
                            name='publicPlace'
                            id='publicPlace'
                            value={formStep2.values.publicPlace}
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.publicPlace &&
                                Boolean(formStep2.errors.publicPlace)
                            }
                            helperText={
                                formStep2.touched.publicPlace && formStep2.errors.publicPlace
                            }
                        />
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.number')}
                            name='numberPlace'
                            id='numberPlace'
                            disabled={
                                hasFoundedCnpjWsInfo || Boolean(cnpjWsProviderInfo?.numberPlace)
                            }
                            value={formStep2.values.numberPlace}
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.numberPlace &&
                                Boolean(formStep2.errors.numberPlace)
                            }
                            helperText={
                                formStep2.touched.numberPlace && formStep2.errors.numberPlace
                            }
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.complement')}
                            name='complementPlace'
                            value={formStep2.values.complementPlace}
                            disabled={
                                hasFoundedCnpjWsInfo || Boolean(cnpjWsProviderInfo?.complementPlace)
                            }
                            onChange={formStep2.handleChange}
                            error={
                                formStep2.touched.complementPlace &&
                                Boolean(formStep2.errors.complementPlace)
                            }
                            helperText={
                                formStep2.touched.complementPlace &&
                                formStep2.errors.complementPlace
                            }
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.district')}
                            name='district'
                            disabled={
                                (hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.district)) ||
                                Boolean(dataViaCep?.bairro && true)
                            }
                            value={formStep2.values.district}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.district && Boolean(formStep2.errors.district)}
                            helperText={formStep2.touched.district && formStep2.errors.district}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={
                                (hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.state)) ||
                                Boolean(dataViaCep?.uf && true)
                            }
                            name='state'
                            label={t('term.select-state')}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.state && Boolean(formStep2.errors.state)}
                            helperText={formStep2.touched.state && formStep2.errors.state}
                            value={formStep2.values.state}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={
                                (hasFoundedCnpjWsInfo && Boolean(cnpjWsProviderInfo?.city)) ||
                                Boolean(dataViaCep?.localidade && true)
                            }
                            label={t('term.city')}
                            name='cityName'
                            value={formStep2.values.cityName}
                            onChange={formStep2.handleChange}
                            error={formStep2.touched.cityName && Boolean(formStep2.errors.cityName)}
                            helperText={formStep2.touched.cityName && formStep2.errors.cityName}
                        />
                    </Grid>
                    {errorSearchCEP && (
                        <Typography color='error'>
                            {t('sign-up.pages.stepper-organization.address.info-error-cep')}
                        </Typography>
                    )}
                    {errorLenghtCEP && (
                        <Typography color='error'>
                            {t(
                                'sign-up.pages.stepper-organization.address.info-error-cep-min-char'
                            )}
                        </Typography>
                    )}
                    {errorMessage && <Typography color='error'>{errorMessage}</Typography>}
                </Grid>
                <div className='stepper-nav'>
                    <Button
                        disabled={loadingProviderContext || errorSearchCEP}
                        size='small'
                        variant='contained'
                        color='primary'
                        type='submit'
                        startIcon={
                            loadingProviderContext && (
                                <CircularProgress color='secondary' size={20} />
                            )
                        }
                    >
                        {t('term.save-and-forward')}
                    </Button>
                </div>
            </form>
        </Grid>
    );
};

export default Address;

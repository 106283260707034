import { IconBaseProps } from 'react-icons';
import { FaBullhorn, FaGavel, FaHandshake, FaIdCard, FaMedal, FaPortrait } from 'react-icons/fa';
import { MdGavel } from 'react-icons/md';
import {
    RiInboxUnarchiveFill,
    RiMoneyDollarCircleFill,
    RiSwordFill,
    RiTrophyFill,
} from 'react-icons/ri';
import { GiMoneyStack } from 'react-icons/gi';
import { ProcessEnum } from 'clients/manager/interfaces/auction-notice.interface';

interface ProcessTypeIconProps extends IconBaseProps {
    icon: ProcessEnum[keyof ProcessEnum];
}

const ProcessTypeIcon = ({ icon, ...props }: ProcessTypeIconProps) => (
    <>
        {icon === 'trading' && <MdGavel {...props} />}
        {icon === 'auction' && <RiMoneyDollarCircleFill {...props} />}
        {icon === 'dispense' && <RiInboxUnarchiveFill {...props} />}
        {icon === 'accreditation' && <FaIdCard {...props} />}
        {icon === 'concurrence' && <RiSwordFill {...props} />}
        {icon === 'unenforceability' && <FaHandshake {...props} />}
        {icon === 'competitiveDialogue' && <RiSwordFill {...props} />}
        {icon === 'contest' && <RiTrophyFill {...props} />}
        {icon === 'inPersonConcurrence' && <FaPortrait {...props} />}
        {icon === 'inPersonTrading' && <FaGavel {...props} />}
        {icon === 'expressionOfInterest' && <FaBullhorn {...props} />}
        {icon === 'preQualification' && <FaMedal {...props} />}
        {icon === 'inPersonAuction' && <GiMoneyStack {...props} />}
        {icon === 'biddingInapplicability' && <RiInboxUnarchiveFill {...props} />}
    </>
);

export default ProcessTypeIcon;

import { Box } from '@material-ui/core';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import ContentTabs from '../content-tabs';
import ProcessLots from './components/process-lots';
import ProcessEditData from './components/process-edit-data';
import { ProcessDataProps } from './props';
import { useProcessFormContext } from '../../context/process-form.context';
import { processRules } from '../../process-utils';
import ErrorStatus from '../error-status';
import { useProcessLotsFormContext } from '../../context/process-lots-form.context';

enum ProcessTab {
    general = 1,
    lotsAndItens = 2,
}

type ContentTab = {
    [key in ProcessTab]: { label: string; title: any; disabled?: boolean };
};

const ProcessData: FC<ProcessDataProps> = () => {
    const [contentTabSelected, setContentTabSelected] = useState<ProcessTab>(ProcessTab.general);
    const { processForm, publishDateError } = useProcessFormContext();
    const { processLotsForm } = useProcessLotsFormContext();
    const { t } = useTranslation();
    const isDraftProccess = !processForm?.values?.id;
    const disabledLotAndItensTab =
        isDraftProccess ||
        !processForm?.values ||
        !processForm.values.auctionType ||
        (!processForm?.values.typeValueBid &&
            processRules.visibleTypeValueBid(processForm?.values));

    const lotsIsValid =
        !!processLotsForm.values.lots.length && !processLotsForm?.errors?.lots?.length;
    const processIsValid = !publishDateError && !Object.keys(processForm?.errors ?? {}).length;

    const contentTabs: ContentTab = {
        [ProcessTab.general]: {
            label: i18n.t('term.general2'),
            title: (
                <Box display='flex' alignItems='center'>
                    {i18n.t('term.general2')}
                    <Box ml={1}>
                        <ErrorStatus
                            isValid={processIsValid}
                            onlyError
                            title={t('info.invalid-fields')}
                        />
                    </Box>
                </Box>
            ),
            disabled: false,
        },
        [ProcessTab.lotsAndItens]: {
            label: i18n.t('term.lots-itens'),
            title: (
                <Box display='flex' alignItems='center'>
                    {i18n.t('term.lots-itens')}
                    <Box ml={1}>
                        {isDraftProccess ? (
                            <ErrorStatus
                                isValid={false}
                                onlyError
                                title={t('info.required-create-process')}
                            />
                        ) : (
                            <>
                                {disabledLotAndItensTab ? (
                                    <ErrorStatus
                                        isValid={false}
                                        onlyError
                                        title={t('info.lots-required-fields')}
                                    />
                                ) : (
                                    <ErrorStatus
                                        isValid={lotsIsValid}
                                        onlyError
                                        title={t('info.invalid-lots')}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                </Box>
            ),
            disabled: disabledLotAndItensTab,
        },
    };

    return (
        <Box>
            {contentTabSelected === ProcessTab.general && (
                <>
                    <ContentTabs
                        tabs={contentTabs}
                        currentTab={contentTabSelected}
                        onTabChanged={(tab) => setContentTabSelected(tab)}
                        visibleUpdateProcess
                        fixed
                    />
                    <ProcessEditData />
                </>
            )}
            {contentTabSelected === ProcessTab.lotsAndItens && (
                <ProcessLots
                    tabs={contentTabs}
                    currentTab={contentTabSelected}
                    onTabChanged={(tab) => setContentTabSelected(tab)}
                />
            )}
        </Box>
    );
};

export default ProcessData;

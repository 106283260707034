import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { CONSTANTS } from 'common/constants';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface TableCompareDatafieldsValues {
    newValue: string | number | null;
    oldValue: string | number | null;
}
interface TableCompareDatafieldsObject {
    companyName: TableCompareDatafieldsValues;
    tradingName: TableCompareDatafieldsValues;
    zipCode: TableCompareDatafieldsValues;
    publicPlace: TableCompareDatafieldsValues;
    numberPlace: TableCompareDatafieldsValues;
    complementPlace: TableCompareDatafieldsValues;
    district: TableCompareDatafieldsValues;
    cityName: TableCompareDatafieldsValues;
    state: TableCompareDatafieldsValues;
    emailForBilling: TableCompareDatafieldsValues;
    emailForNotifications: TableCompareDatafieldsValues;
    billingPhoneNumber: TableCompareDatafieldsValues;
    phoneNumber: TableCompareDatafieldsValues;
    intructionLevel: TableCompareDatafieldsValues;
    birthplace: TableCompareDatafieldsValues;
    birthdate: TableCompareDatafieldsValues;
    gender: TableCompareDatafieldsValues;
    profession: TableCompareDatafieldsValues;
    socialSecurityCode: TableCompareDatafieldsValues;
}
interface TableCompareDatafieldsProps {
    datafields: TableCompareDatafieldsObject;
}

const TableDatafieldsView: React.FC<TableCompareDatafieldsProps> = ({ datafields }) => {
    const { t } = useTranslation();

    const CodeValues = ['intructionLevel', 'birthplace', 'profession', 'birthdate', 'gender'];

    const getNameCode = (propertie: string, oldOrNew: string) => {
        let descriptionCode;

        switch (propertie) {
            case 'intructionLevel':
                descriptionCode = CONSTANTS.instructionLevelConstants.find(
                    (item) => item.id == datafields.intructionLevel[oldOrNew]
                );
                break;

            case 'birthplace':
                descriptionCode = CONSTANTS.municipalityConstants.find(
                    (item) => item.id == datafields.birthplace[oldOrNew]
                );
                break;

            case 'profession':
                descriptionCode = CONSTANTS.professionConstants.find(
                    (item) => item.id == datafields.profession[oldOrNew]
                );
                break;

            case 'birthdate':
                descriptionCode = datafields.gender[oldOrNew]
                    ? {
                          id: 0,
                          description: moment(datafields.birthdate[oldOrNew]).format('DD/MM/YYYY'),
                      }
                    : {
                          id: 0,
                          description: '',
                      };
                break;

            case 'gender':
                descriptionCode = !datafields.gender[oldOrNew]
                    ? { id: 0, description: '' }
                    : datafields.gender[oldOrNew] == 'F'
                    ? { id: 0, description: t('term.gender.female.complete') }
                    : { id: 0, description: t('term.gender.male.complete') };
                break;

            default:
                break;
        }

        if (descriptionCode) return descriptionCode.description;
        return '';
    };

    const dataFielsTranslateFormat = {
        companyName: 'term.corporate-name',
        tradingName: 'term.fantasy-name',
        zipCode: 'bidding.process.lot.provider.dialog.zip-code',
        ibgeCode: 'term.ibge-code',
        publicPlace: 'term.address',
        numberPlace: 'term.number',
        complementPlace: 'term.complement',
        district: 'term.district',
        cityName: 'term.city',
        state: 'term.state',
        emailForBilling: 'term.email-for-billing',
        emailForNotifications: 'term.email-for-notifications',
        billingPhoneNumber: 'term.billing-phone-number',
        phoneNumber: 'term.phone',
        intructionLevel: 'term.instruction-level',
        birthplace: 'term.birthplace',
        birthdate: 'term.birth-date',
        gender: 'term.gender',
        profession: 'term.profession',
        socialSecurityCode: 'term.worker-registration-number',
        docNumberReceiver: 'sign-up.pages.stepper-provider.steps.financial.docNumberReceiver',
        exerciseYear: 'sign-up.pages.stepper-provider.steps.exerciseYear',
        classification: 'sign-up.pages.stepper-provider.steps.financial.classification',
        operationalResult: 'sign-up.pages.stepper-provider.steps.operationalResult',
        netWorth: 'sign-up.pages.stepper-provider.steps.netWorth',
        workingCapital: 'sign-up.pages.stepper-provider.steps.workingCapital',
        stateCompanyRegistrationNumber: 'term.state-registration-number',
        docType: 'term.type',
        docNumber: 'term.docNumber',
        docNumberMei: 'CPF Mei',
        itsSimpleCompany: 'term.tax-regime',
    };

    return (
        <TableContainer component={Paper} style={{ marginTop: '15px' }}>
            <Table style={{ minWidth: 450 }}>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.field')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.current')}
                        </TableCell>
                        <TableCell style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}>
                            {t('term.proposed')}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {datafields &&
                        Object.keys(datafields).map((key) => {
                            return (
                                <TableRow>
                                    <TableCell
                                        style={{ backgroundColor: '#eaeaea', fontWeight: 'bold' }}
                                    >
                                        {t(dataFielsTranslateFormat[key])}
                                    </TableCell>
                                    {CodeValues.includes(key) ? (
                                        <TableCell>{getNameCode(key, 'oldValue')}</TableCell>
                                    ) : (
                                        <TableCell>{datafields?.[key]?.oldValue}</TableCell>
                                    )}
                                    {CodeValues.includes(key) ? (
                                        <TableCell>{getNameCode(key, 'newValue')}</TableCell>
                                    ) : (
                                        <TableCell>{datafields?.[key]?.newValue}</TableCell>
                                    )}
                                </TableRow>
                            );
                        })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default TableDatafieldsView;

import { FC, useMemo } from 'react';
import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { useTranslation } from 'react-i18next';
import { TypeOfBenefitValue } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { AuctionLotFormValues } from '../../../../context/lots.form';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';

export interface QuotaSelectReferenceProps {
    lotId: number;
    values: AuctionLotFormValues;
    disabled: boolean;
}

const QuotaSelectReference: FC<QuotaSelectReferenceProps> = ({ lotId, values, disabled }) => {
    const { processLotsForm, setFieldValue } = useProcessLotsFormContext();
    const { t } = useTranslation();

    const lotQuotaOpts = useMemo(
        () =>
            processLotsForm.values.lots
                .filter(
                    (currLot) =>
                        currLot.typeOfBenefit !== TypeOfBenefitValue.reservedQuota &&
                        currLot.id !== lotId
                )
                .map((lot) => <MenuItem value={lot.id}>{`${t('term.lot')} ${lot.item}`}</MenuItem>),
        [processLotsForm.values.lots.length]
    );

    return (
        <TextField
            fullWidth
            id='select'
            color='primary'
            disabled={disabled || !lotQuotaOpts.length}
            label='Lote pai'
            defaultValue={values.quotaId}
            value={values.quotaId}
            onChange={(event) => setFieldValue(values, 'quotaId', event.target.value)}
            variant='outlined'
            select
        >
            {lotQuotaOpts}
        </TextField>
    );
};

export default QuotaSelectReference;

import { memo, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItem, Menu } from '@material-ui/core';
import { Button } from 'common/components';
import { ArrowDropDown } from '@material-ui/icons';
import {
    processStageToBiddingStageId,
    addNotificationApiError,
    apiRedirectTo,
    getBackofficeRedirectUrl,
} from 'common/utils';
import { BiddingStageEnum } from 'common/enums/bidding-stage.enum';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import ReactDOM from 'react-dom';
import { useProcessFormContext } from '../../context/process-form.context';
import {
    AuctionType,
    ProcessStage,
} from '../../../../clients/manager/interfaces/auction-notice.interface';
import { organizationMinutesRequests } from '../../../../clients/manager/organization-minutes.requests';
import { ProcessMinuteResponse } from '../../../../clients/manager/interfaces/organization-minutes.interface';
import ModalImportExport from '../process-data/components/modal-import-export';

const ButtonProcessHistory = () => {
    const { t } = useTranslation();
    const { process, processForm } = useProcessFormContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [fetchingMinutes, setFetchingMinutes] = useState<boolean>(true);
    const [biddingStage, setBiddingStage] = useState<number>();
    const [auctionType, setAuctionType] = useState<string>();
    const [processMinutes, setProcessMinutes] = useState<ProcessMinuteResponse[]>([]);
    const [isVisibleModalImport, setIsVisibleModalImport] = useState(false);
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const inBiddingStage = useCallback(
        (biddingStage: number[]) =>
            processForm?.values?.biddingStageId &&
            biddingStage.includes(processForm.values.biddingStageId),
        [processForm?.values?.biddingStageId]
    );

    const backofficeRedirectUrl = getBackofficeRedirectUrl();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const canRenderComponent =
        !process ||
        // para os outros mostra se está na etapa de contrato ou decisão
        (processForm?.values?.auctionType !== AuctionType.accreditation &&
            !inBiddingStage(
                processStageToBiddingStageId([ProcessStage.contract, ProcessStage.decision])
            )) ||
        // só mostra componente se for credenciamento e o biddingStageId >= 8
        (processForm?.values?.auctionType === AuctionType.accreditation &&
            processForm?.values?.biddingStageId <= 8);

    useEffect(() => {
        if (process) {
            setBiddingStage(process.biddingStageId);
            setAuctionType(process.auctionType);
        }

        const getProcessMinutes = async () => {
            if (!canRenderComponent || processMinutes.length || !process) {
                return;
            }

            try {
                const processMinutes = await organizationMinutesRequests.getProcessMinutes(
                    {
                        processId: process.id,
                        isOrganization: true,
                    },
                    authUser?.id === undefined
                );
                setProcessMinutes(processMinutes.data);
            } catch (error) {
                addNotificationApiError(error);
            } finally {
                setFetchingMinutes(false);
            }
        };

        getProcessMinutes();
    }, [canRenderComponent, process, authUser]);

    const shouldRenderButton =
        (biddingStage && biddingStage >= BiddingStageEnum.decision) ||
        (biddingStage &&
            biddingStage >= BiddingStageEnum.published &&
            auctionType === AuctionType.accreditation);

    const getOptions = () => {
        if (fetchingMinutes) {
            return <MenuItem>{t('term.loading')}...</MenuItem>;
        }

        if (processMinutes.length) {
            return processMinutes.map(({ name, url, type }) => (
                <MenuItem
                    onClick={() =>
                        window
                            .open(
                                apiRedirectTo(
                                    backofficeRedirectUrl,
                                    url,
                                    type === 'custom',
                                    true
                                ) as string
                            )
                            ?.focus()
                    }
                >
                    {type === 'custom' ? name : t(name)}
                </MenuItem>
            ));
        }
    };

    const handleClickExport = () => {
        ReactDOM.unstable_batchedUpdates(() => {
            handleClose();
            setIsVisibleModalImport(true);
        });
    };

    return (
        <>
            {isVisibleModalImport && process && (
                <ModalImportExport
                    type='export'
                    auctionId={process.id}
                    onClose={() => setIsVisibleModalImport(false)}
                />
            )}
            {shouldRenderButton && (
                <Button
                    color='primary'
                    variant='outlined'
                    disabled={false}
                    size='xsmall'
                    aria-haspopup='true'
                    title={t('term.actions')}
                    onClick={handleClick}
                    endIcon={<ArrowDropDown />}
                >
                    {t('term.history')}
                </Button>
            )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                keepMounted
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {getOptions()}
                <MenuItem onClick={handleClickExport}>{t('term.export')}</MenuItem>
            </Menu>
        </>
    );
};

export default memo(ButtonProcessHistory);

import { FC, memo } from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import { ModalExportLotsAndItensProps } from './props';

const ModalExportLotsAndItens: FC<ModalExportLotsAndItensProps> = ({
    setOnlyWinners,
    setAsync,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Box display='flex' justifyContent='center' mt={2} mb={2}>
                <FormControlLabel
                    control={<Checkbox />}
                    label={t('process.components.only-winners')}
                    onChange={(event: React.ChangeEvent<any>) =>
                        setOnlyWinners(event.target.checked)
                    }
                />
                <HelpIconLabel title={t('process.components.async-help')}>
                    <FormControlLabel
                        control={<Checkbox />}
                        label={t('process.components.async')}
                        onChange={(event: React.ChangeEvent<any>) => setAsync(event.target.checked)}
                    />
                </HelpIconLabel>
            </Box>
        </>
    );
};

export default memo(ModalExportLotsAndItens);

import { CONSTANTS } from 'common/constants';
import { getAuthToken, getEnableHttpOnlyToken } from 'modules/auth/session.utils';

export const redirectTo = (domain: string, to: string, custom?: boolean) => {
    const redirectTo = (url: string) => window.location.replace(url);

    if (custom) {
        return redirectTo(to);
    }

    if (to.startsWith('/')) {
        to = to.replace(/^\//, '');
    }

    return redirectTo(`${domain}/${to}`);
};

export const apiRedirectTo = (
    backofficeRedirectUrl: string,
    redirectUrl: string,
    isPublic?: boolean,
    disableRedirect?: boolean
) => {
    const { enableHttpToken } = getEnableHttpOnlyToken();
    const { token } = getAuthToken();

    const url = isPublic
        ? `${backofficeRedirectUrl}/${redirectUrl}`
        : `${CONSTANTS.MANAGER_API_URL}/auth/redirectTo?url=${backofficeRedirectUrl}&token=${
              enableHttpToken ? '' : token
          }&redirectUrl=${redirectUrl}`;

    if (disableRedirect) return url;

    window.location.replace(url);
};

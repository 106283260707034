import { AppBar, Badge, Box, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Notifications, NotificationsOutlined, Menu } from '@material-ui/icons';
import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { timeout } from 'common/utils';
import { CONSTANTS } from 'common/constants';
import { usePageContext } from 'common/components/base/pages/context';
import { notificationsRequests } from 'clients/manager/notifications.requests';
import { UseWindowEvent } from 'common/hooks/events.hook';
import { AppState } from 'store';
import { HiOutlineSpeakerphone } from 'react-icons/hi';
import { usePlatformContext } from 'routes/platform.context';
import { AuthenticatedHeaderProps } from './props';
import { Container, useStyles } from './styles';
import EnvironmentsModal from './components/environments-modal';
import { useMenuHeaderContext } from './menu-header.context';

const AuthenticatedHeader: FC<AuthenticatedHeaderProps> = ({ onExpandMenu }) => {
    const classes = useStyles();
    const { notificationsCountContext } = useMenuHeaderContext();
    const { title } = usePageContext();
    const { platform } = usePlatformContext();
    const { authUser } = useSelector((state: AppState) => state.authUserState);
    const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth);
    const history = useHistory();

    const resize = useCallback(() => {
        setWindowWidth(window.innerWidth);
    }, [windowWidth]);

    UseWindowEvent('resize', () => resize(), [resize]);

    const [notificationsCount, setNotificationsCount] = useState(0);

    const getNotificationsCount = async () => {
        if (!authUser) {
            return;
        }

        const response = await notificationsRequests.getNotificationsCount();
        setNotificationsCount(response?.data?.count ?? 0);
    };

    useEffect(() => {
        timeout(() => getNotificationsCount(), 5_000);
    }, [authUser]);

    useEffect(() => {
        setNotificationsCount(notificationsCountContext);
    }, [notificationsCountContext]);

    const mobile = windowWidth < CONSTANTS.mobileWidth;

    return (
        <Container>
            <div className={classes.root}>
                <AppBar position='static' className={classes.appBar}>
                    <Toolbar style={{ height: '100%' }}>
                        <Box
                            display='flex'
                            width='100%'
                            justifyContent='flex-end'
                            alignItems='center'
                        >
                            {mobile && (
                                <IconButton
                                    aria-label='menu'
                                    color='primary'
                                    className='extra-icons-header'
                                    onClick={onExpandMenu}
                                    style={{ margin: '0 16px 0 0', padding: 0 }}
                                >
                                    <Menu />
                                </IconButton>
                            )}

                            <Typography
                                id='titleApplication'
                                style={{
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                }}
                                variant='h6'
                                className={classes.title}
                            >
                                {title}
                            </Typography>
                            <Box display='flex'>
                                <Box display='flex' alignItems='center'>
                                    {!mobile &&
                                        !!platform?.generalSettings?.general
                                            ?.showProductMarketing && (
                                            <IconButton id='beamerButton'>
                                                <HiOutlineSpeakerphone
                                                    color='var(--icon-color)'
                                                    size={26}
                                                />
                                            </IconButton>
                                        )}
                                    {!mobile && (
                                        <>
                                            <IconButton
                                                aria-label='notificações'
                                                onClick={() => history.push('/notificacoes')}
                                            >
                                                <Badge
                                                    badgeContent={notificationsCount}
                                                    color='error'
                                                >
                                                    {notificationsCount > 0 ? (
                                                        <Notifications />
                                                    ) : (
                                                        <NotificationsOutlined />
                                                    )}
                                                </Badge>
                                            </IconButton>
                                        </>
                                    )}
                                    <EnvironmentsModal />
                                </Box>
                            </Box>
                        </Box>
                    </Toolbar>
                </AppBar>
            </div>
        </Container>
    );
};

export default AuthenticatedHeader;

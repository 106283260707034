import { FcGoogle } from 'react-icons/fc';
import { CognitoHostedUIIdentityProvider } from 'common/amplify';
import { MicrosoftIcon } from 'common/icons';
import { usePlatformContext } from 'routes/platform.context';

export const useIdentityProviders = () => {
    const { platform } = usePlatformContext();

    const identityProviders = [
        {
            name: CognitoHostedUIIdentityProvider.Google,
            icon: <FcGoogle size={22} />,
            enabled: Boolean(platform?.generalSettings?.cognito?.enableGoogleLogin),
        },
        {
            name: CognitoHostedUIIdentityProvider.Microsoft,
            icon: <MicrosoftIcon width={22} height={22} />,
            enabled: Boolean(platform?.generalSettings?.cognito?.enableMicrosoftLogin),
        },
    ];

    return { identityProviders };
};

export type IdentityProvider = {
    name: CognitoHostedUIIdentityProvider;
    icon: JSX.Element;
    enabled: boolean;
};

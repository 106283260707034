import EditorCreator from './editor-creator';
import EditorReplacer from './editor-replacer';

export interface EditorVariable {
    id: string;
    name: string;
    description: string;
    custom?: boolean;
}

const EDITOR_ID = 'document-editor';
const EDITOR_IFRAME_ID = `${EDITOR_ID}_ifr`;

const VARIABLE_TRIGGER = '@';
const VARIABLE_ELEMENT_CLASSNAME = 'variable';

const EDITOR_KEY =
    process.env.REACT_APP_TINY_ACCESS_KEY ?? 'j2m3dd9e14ftd0vzvycjb0hl8yl4z92avaxrdcc3gwr3x23a';

export {
    EditorCreator,
    EditorReplacer,
    EDITOR_ID,
    EDITOR_IFRAME_ID,
    VARIABLE_TRIGGER,
    VARIABLE_ELEMENT_CLASSNAME,
    EDITOR_KEY,
};

import ProcessTypeIcon from 'modules/bidding/components/process-type-icon';

export default [
    {
        value: 'all',
    },
    {
        value: 'trading',
        icon: <ProcessTypeIcon icon='trading' />,
    },
    {
        value: 'auction',
        icon: <ProcessTypeIcon icon='auction' />,
    },
    {
        value: 'dispense',
        icon: <ProcessTypeIcon icon='dispense' />,
    },
    {
        value: 'accreditation',
        icon: <ProcessTypeIcon icon='accreditation' />,
    },
    {
        value: 'concurrence',
        icon: <ProcessTypeIcon icon='concurrence' />,
    },
    {
        value: 'unenforceability',
        icon: <ProcessTypeIcon icon='unenforceability' />,
    },
    {
        value: 'competitiveDialogue',
        icon: <ProcessTypeIcon icon='competitiveDialogue' />,
    },
    {
        value: 'contest',
        icon: <ProcessTypeIcon icon='contest' />,
    },
    {
        value: 'inPersonConcurrence',
        icon: <ProcessTypeIcon icon='inPersonConcurrence' />,
    },
    {
        value: 'inPersonTrading',
        icon: <ProcessTypeIcon icon='inPersonTrading' />,
    },
    {
        value: 'expressionOfInterest',
        icon: <ProcessTypeIcon icon='expressionOfInterest' />,
    },
    {
        value: 'preQualification',
        icon: <ProcessTypeIcon icon='preQualification' />,
    },
    {
        value: 'inPersonAuction',
        icon: <ProcessTypeIcon icon='inPersonAuction' />,
    },
    {
        value: 'biddingInapplicability',
        icon: <ProcessTypeIcon icon='biddingInapplicability' />,
    },
];

import { FC } from 'react';
import { Box, makeStyles, Tooltip, IconButton, Typography } from '@material-ui/core';
import { Button } from 'common/components';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SyncIcon from '@material-ui/icons/Sync';
import { useTranslation } from 'react-i18next';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { formatLotName } from 'common/utils';
import { DeleteIcon } from 'common/icons';
import ErrorStatus from 'modules/process/components/error-status';
import { AuctionLotWithItemsForm } from 'modules/process/context/lots.form';
import { FormikProps } from 'formik';
import { getFormErrorsLabels } from '../../form-errors';
import { ProcessLotHeaderProps } from './process-lot-header';

export const useStyles = makeStyles(() => ({
    editIcon: {
        color: '#df9d17',
    },
    tooltip: {
        fontSize: '14px',
    },
}));

interface ProcessLotHeaderViewProps extends ProcessLotHeaderProps {
    processLotsForm: FormikProps<AuctionLotWithItemsForm>;
    lotItemIdentifier: number;
    isImportedLot: boolean;
    isLotValid: boolean;
    canDeleteLot: boolean;
    canEditLot: boolean;
}

const ProcessLotHeaderView: FC<ProcessLotHeaderViewProps> = ({
    lot,
    lotHasChanged,
    visibleAdvancedInfoBlock,
    expandedLot,
    onExpandLot,
    onClickDeleteLot,
    onClickAdvancedInfo,
    canEditLot,
    canDeleteLot,
    isLotValid,
    isImportedLot,
    lotItemIdentifier,
    processLotsForm,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    return (
        <Box
            width={1}
            display='flex'
            justifyContent='space-between'
            onClick={(event) => {
                event.stopPropagation();
                onExpandLot(!expandedLot);
            }}
        >
            <Box display='flex' alignItems='center'>
                <Typography variant='body1'>
                    <b>{`${t('term.lot')} ${formatLotName(lotItemIdentifier, lot.quotaId)}`}</b>{' '}
                    &nbsp;
                    <span style={{ fontSize: 11 }}>
                        {lot.externalItemId ? `(${lot.externalItemId})` : ''}
                    </span>
                    {visibleAdvancedInfoBlock && (
                        <Button
                            style={{ textTransform: 'none', fontSize: 13 }}
                            variant='text'
                            color='link'
                            size='small'
                            onClick={(event) => {
                                event.stopPropagation();
                                onClickAdvancedInfo();
                            }}
                        >
                            <b>{t('term.info-advanced')}</b> &nbsp;
                            <KeyboardArrowDownIcon />
                        </Button>
                    )}
                </Typography>
                {canEditLot && (!lot.quotaId || !isImportedLot) && (
                    <ErrorStatus
                        isValid={isLotValid}
                        title={getFormErrorsLabels(processLotsForm, lot.id)}
                    />
                )}
                {lotHasChanged && (!lot.quotaId || !isImportedLot) && (
                    <Box ml={1} display='flex' alignItems='center'>
                        <Tooltip
                            aria-label='info'
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                            title={t('info.pending-lot-changes')}
                        >
                            <SyncIcon className={classes.editIcon} />
                        </Tooltip>
                    </Box>
                )}
            </Box>
            <Box display='flex' alignItems='center'>
                {canDeleteLot && (
                    <ConfirmDialog
                        title={t('process.components.message-confirm-delete-lot')}
                        message={t('info.message-confirm-delete-lot')}
                        onConfirm={onClickDeleteLot}
                    >
                        <IconButton title={t('info.delete-lot')} aria-label='delete' edge='end'>
                            <DeleteIcon />
                        </IconButton>
                    </ConfirmDialog>
                )}
                <IconButton title={t('info.expand-lot')} aria-label='expand' edge='end'>
                    <KeyboardArrowDownIcon />
                </IconButton>
            </Box>
        </Box>
    );
};

export default ProcessLotHeaderView;

import { Box, CircularProgress, Divider, Grid, MenuItem, Typography } from '@material-ui/core';
import { Autocomplete, Skeleton } from '@material-ui/lab';
import { TextField, MaskedTextField } from 'common/components';
import IdentificationNumberTextField from 'common/components/identification-number-text-field';
import { useFormikContext } from 'formik';
import { FC, useState, FocusEvent } from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import PersonPhoneInput from 'common/components/phone-input';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useIBGECountries } from 'common/hooks/use-ibge-countries.hook';
import { timeout } from 'common/utils';
import { CONSTANTS } from 'common/constants';
import { addressRequest } from 'clients/manager/address.requests';
import { Provider } from 'clients/manager/interfaces/provider.interface';
import LoadingButton from 'common/components/loading-button';
import { DataViaCep } from 'common/interfaces/via-cep-data';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import * as momentConfig from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';
import { useProviderUpdateRequestWarningContext } from 'common/components/provider-update-request-warning/context';
import { CompanyFormProps } from './props';
import StateRegistrationNumber from '../state-registration-number';

moment.locale('pt-br');
momentConfig.tz.setDefault('America/Sao_Paulo');
momentDurationFormatSetup(momentConfig);

const CompanyForm: FC<CompanyFormProps> = ({ updatingProvider, provider, disabled = false }) => {
    const { t } = useTranslation();
    const { setCountryCode } = useProviderUpdateRequestWarningContext();
    const form = useFormikContext<Provider>();
    const [loadingAddressFields, setLoadingAddressFields] = useState<boolean>(false);
    const [errorSearchCEP, setErrorSearchCEP] = useState(false);
    const [errorLenghtCEP, setErrorLenghtCEP] = useState(false);
    const [country, setCountry] = useState(provider?.country);
    const [exemptStateRegistration, setExemptStateRegistration] = useState(false);
    const [dataViaCep, setDataViaCep] = useState<DataViaCep>();
    const { IBGEcountries } = useIBGECountries();

    const birthplace = CONSTANTS.municipalityConstants.find(
        (item) => item.id === form.values.birthplace
    );
    const profession = CONSTANTS.professionConstants.find(
        (item) => item.id === form.values.profession
    );
    const intructionLevel = CONSTANTS.instructionLevelConstants.find(
        (item) => item.id === form.values.intructionLevel
    );

    const onBlurCep = async (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        if (provider?.country !== CONSTANTS.ibgeCodes.BRAZIL) return;

        const cepValue = e.target.value;
        const cep = cepValue?.replace(/[^0-9]/g, '');

        if (cep?.length > 8) {
            setErrorLenghtCEP(true);
            timeout(() => {
                setErrorLenghtCEP(false);
            }, 3000);
        }

        if (cep?.length !== 8) {
            return;
        }

        setLoadingAddressFields(true);

        const address = await addressRequest.getAddress(cep);
        ReactDOM.unstable_batchedUpdates(() => {
            if (!address.localidade) {
                setErrorSearchCEP(true);
            } else {
                setErrorSearchCEP(false);
            }

            setDataViaCep(address);

            form.setValues({
                ...form.values,
                publicPlace: address.logradouro,
                district: address.bairro,
                cityName: address.localidade,
                state: address.uf,
                ibgeCode: address.ibge,
            });
            setLoadingAddressFields(false);
        });
    };

    if (!form.values?.docNumber && !form.values?.companyName) {
        return (
            <Box p={2}>
                <Grid container>
                    {new Array(4).fill(null).map((_, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Skeleton key={index} height='45px' width='100%' />
                    ))}
                </Grid>
            </Box>
        );
    }

    const formaterDocNumber = (personType, country) => {
        if (country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.natural-person')) {
            form.setFieldValue('docNumber', form.values.docNumber?.slice(0, 11));
        }
        if (country === CONSTANTS.ibgeCodes.BRAZIL && personType === t('term.legal-person')) {
            form.setFieldValue('docNumber', form.values.docNumber?.slice(0, 14));
        }
    };

    const returnDocType = () => (form.values.docType == 'F' ? 'J' : 'F');

    const countryOnChange = (e) => {
        setCountry(e.target.value);
        setCountryCode(e.target.value);

        const personType =
            form.values.docType == 'F' ? t('term.natural-person') : t('term.legal-person');
        formaterDocNumber(personType, e.target.value);
        form.setFieldValue('country', e.target.value);
        if (e.target.value !== CONSTANTS.ibgeCodes.BRAZIL) {
            form.setFieldValue('stateCompanyRegistrationNumber', 'Isento');
        }
    };

    return (
        <Box p={2}>
            <Grid container>
                <Grid item container spacing={1}>
                    <Grid item xs={3}>
                        <TextField
                            select
                            label={t('term.country')}
                            disabled={!!provider?.country || disabled}
                            fullWidth
                            name='country'
                            value={form.values.country}
                            onChange={countryOnChange}
                            error={form.touched.country && !!form.errors.country}
                            helperText={form.touched.country && form.errors.country}
                        >
                            {IBGEcountries.map(({ code, name }) => (
                                <MenuItem key={code} value={code}>
                                    {name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            select
                            label={t('term.type')}
                            fullWidth
                            disabled={!!provider?.docType || disabled}
                            name='docType'
                            value={
                                form.values.docType === 'F'
                                    ? t('term.natural-person')
                                    : t('term.legal-person')
                            }
                            onChange={(e) => {
                                formaterDocNumber(e.target.value, country);
                                form.setFieldValue('docType', returnDocType());
                            }}
                            error={form.touched.docType && !!form.errors.docType}
                            helperText={form.touched.docType && form.errors.docType}
                        >
                            {[t('term.natural-person'), t('term.legal-person')].map((value) => (
                                <MenuItem key={value} value={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>

                    <Grid item xs={3}>
                        <IdentificationNumberTextField
                            identificationNumber={form.values.docNumber}
                            country={form.values.country}
                            fieldName='docNumber'
                            disabled={!!provider?.docNumber || disabled}
                            type={form.values.docType === 'J' ? 'cnpj' : 'cpf'}
                            {...form}
                        />
                    </Grid>
                    {form.values.docType === 'J' ? (
                        <StateRegistrationNumber
                            form={form}
                            disabled={disabled}
                            exemptStateRegistration={exemptStateRegistration}
                            setExemptStateRegistration={setExemptStateRegistration}
                            t={t}
                            country={provider?.country}
                        />
                    ) : (
                        country === CONSTANTS.ibgeCodes.BRAZIL && (
                            <>
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        label={t('term.worker-registration-number')}
                                        name='socialSecurityCode'
                                        value={form.values.socialSecurityCode}
                                        onChange={form.handleChange}
                                        disabled={disabled}
                                        error={
                                            form.touched.socialSecurityCode &&
                                            Boolean(form.errors.socialSecurityCode)
                                        }
                                        helperText={
                                            form.touched.socialSecurityCode &&
                                            form.errors.socialSecurityCode
                                        }
                                    />
                                </Grid>
                            </>
                        )
                    )}
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                form.values.docType == 'J'
                                    ? t('term.corporate-name')
                                    : t('term.full-name')
                            }
                            name='companyName'
                            value={form.values.companyName}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={form.touched.companyName && Boolean(form.errors.companyName)}
                            helperText={form.touched.companyName && form.errors.companyName}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label={
                                form.values.docType == 'J'
                                    ? t('term.fantasy-name')
                                    : t(
                                          'bidding.process.lot.provider.dialog.provider-professional-name'
                                      )
                            }
                            name='tradingName'
                            value={form.values.tradingName}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={form.touched.tradingName && Boolean(form.errors.tradingName)}
                            helperText={form.touched.tradingName && form.errors.tradingName}
                        />
                    </Grid>

                    {form.values.docType == 'J' && (
                        <Grid item xs={3}>
                            <TextField
                                select
                                label={t('term.tax-regime')}
                                fullWidth
                                name='itsSimpleCompany'
                                value={form.values.itsSimpleCompany}
                                onChange={(e) => {
                                    form.setFieldValue('itsSimpleCompany', e.target.value);
                                    form.setFieldValue('docNumberMei', '');
                                }}
                                disabled={disabled}
                                error={form.touched.docType && !!form.errors.docType}
                                helperText={form.touched.docType && form.errors.docType}
                            >
                                {[
                                    t('term.tax-regime.others'),
                                    t('term.tax-regime.me-epp'),
                                    t('term.tax-regime.mei'),
                                ].map((value, index) => (
                                    <MenuItem key={value} value={index}>
                                        {value}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}

                    {form.values.itsSimpleCompany == 2 && form.values.docType == 'J' && (
                        <Grid item xs={3}>
                            <IdentificationNumberTextField
                                identificationNumber={form.values.docNumberMei}
                                country={form.values.country}
                                disabled={disabled}
                                fieldName='docNumberMei'
                                type='cpf'
                                {...form}
                            />
                        </Grid>
                    )}

                    {((form.values.itsSimpleCompany == 2 && form.values.docType == 'J') ||
                        form.values.docType === 'F') && (
                        <>
                            <Grid item xs={2}>
                                <KeyboardDatePicker
                                    autoOk
                                    variant='inline'
                                    inputVariant='outlined'
                                    label={t('term.birth-date')}
                                    name='birthdate'
                                    format='DD/MM/yyyy'
                                    value={form.values.birthdate}
                                    InputAdornmentProps={{ position: 'end' }}
                                    invalidDateMessage={t('info.invalid-format', {
                                        ns: 'validation',
                                    })}
                                    invalidLabel={t('info.invalid-date', { ns: 'validation' })}
                                    onChange={(date) => {
                                        form.setFieldValue(
                                            'birthdate',
                                            new Date(moment(date).format('YYYY/MM/DD'))
                                        );
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                <TextField
                                    select
                                    label={t('term.gender')}
                                    fullWidth
                                    name='gender'
                                    value={form.values.gender}
                                    onChange={(e) => {
                                        form.setFieldValue('gender', e.target.value);
                                    }}
                                    disabled={disabled}
                                    error={form.touched.docType && !!form.errors.docType}
                                    helperText={form.touched.docType && form.errors.docType}
                                >
                                    {[t('term.gender.male'), t('term.gender.female')].map(
                                        (value) => (
                                            <MenuItem key={value} value={value}>
                                                {value}
                                            </MenuItem>
                                        )
                                    )}
                                </TextField>
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id='birthplace'
                                    value={birthplace}
                                    options={CONSTANTS.municipalityConstants}
                                    getOptionLabel={(option) => option.description}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('term.birthplace')} />
                                    )}
                                    onChange={(e, value) => {
                                        form.setFieldValue('birthplace', value?.id);
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id='intructionLevel'
                                    value={intructionLevel}
                                    options={CONSTANTS.instructionLevelConstants}
                                    getOptionLabel={(option) => option.description}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label={t('term.instruction-level')}
                                        />
                                    )}
                                    onChange={(e, value) => {
                                        form.setFieldValue('intructionLevel', value?.id);
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <Autocomplete
                                    id='profession'
                                    value={profession}
                                    options={CONSTANTS.professionConstants}
                                    getOptionLabel={(option) => option.description}
                                    renderInput={(params) => (
                                        <TextField {...params} label={t('term.profession')} />
                                    )}
                                    onChange={(e, value) => {
                                        form.setFieldValue('profession', value?.id);
                                    }}
                                    disabled={disabled}
                                />
                            </Grid>
                        </>
                    )}
                </Grid>
                <Box mt={3} mb={3} width={1}>
                    <Typography variant='h6'>Endereço</Typography>
                    <Divider component='h6' />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={2} sm={12} xs={12}>
                        {form.values.country === CONSTANTS.ibgeCodes.BRAZIL ? (
                            <MaskedTextField
                                mask='99999-999'
                                fullWidth
                                label='CEP'
                                name='zipCode'
                                value={form.values.zipCode}
                                onChange={form.handleChange}
                                disabled={disabled}
                                error={form.touched.zipCode && Boolean(form.errors.zipCode)}
                                helperText={form.touched.zipCode && form.errors.zipCode}
                                onBlur={(e) => onBlurCep(e)}
                                InputProps={{
                                    endAdornment: loadingAddressFields && (
                                        <CircularProgress color='secondary' size={20} />
                                    ),
                                }}
                            />
                        ) : (
                            <TextField
                                fullWidth
                                label='CEP'
                                name='zipCode'
                                value={form.values.zipCode}
                                onChange={form.handleChange}
                                disabled={disabled}
                                onBlur={(e) => onBlurCep(e)}
                                error={form.touched.zipCode && Boolean(form.errors.zipCode)}
                                helperText={form.touched.zipCode && form.errors.zipCode}
                            />
                        )}
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.logradouro) || disabled}
                            label={t('term.address')}
                            name='publicPlace'
                            value={form.values.publicPlace}
                            onChange={form.handleChange}
                            error={form.touched.publicPlace && Boolean(form.errors.publicPlace)}
                            helperText={form.touched.publicPlace && form.errors.publicPlace}
                            InputProps={{
                                endAdornment: loadingAddressFields && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.number')}
                            name='numberPlace'
                            value={form.values.numberPlace}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={form.touched.numberPlace && Boolean(form.errors.numberPlace)}
                            helperText={form.touched.numberPlace && form.errors.numberPlace}
                            InputProps={{
                                endAdornment: loadingAddressFields && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.complement')}
                            name='complementPlace'
                            value={form.values.complementPlace}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={
                                form.touched.complementPlace && Boolean(form.errors.complementPlace)
                            }
                            helperText={form.touched.complementPlace && form.errors.complementPlace}
                            InputProps={{
                                endAdornment: loadingAddressFields && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label={t('term.district')}
                            name='district'
                            disabled={Boolean(dataViaCep?.bairro) || disabled}
                            value={form.values.district}
                            onChange={form.handleChange}
                            error={form.touched.district && Boolean(form.errors.district)}
                            helperText={form.touched.district && form.errors.district}
                            InputProps={{
                                endAdornment: loadingAddressFields && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.uf) || disabled}
                            name='state'
                            label={t('term.select-state')}
                            onChange={form.handleChange}
                            error={form.touched.state && Boolean(form.errors.state)}
                            helperText={form.touched.state && form.errors.state}
                            value={form.values.state}
                        />
                    </Grid>
                    <Grid item md={3} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            disabled={Boolean(dataViaCep?.localidade) || disabled}
                            label={t('term.city')}
                            name='cityName'
                            value={form.values.cityName}
                            onChange={form.handleChange}
                            error={form.touched.cityName && Boolean(form.errors.cityName)}
                            helperText={form.touched.cityName && form.errors.cityName}
                            InputProps={{
                                endAdornment: loadingAddressFields && (
                                    <CircularProgress color='secondary' size={20} />
                                ),
                            }}
                        />
                    </Grid>
                    {errorSearchCEP && (
                        <Typography color='error'>
                            {t('sign-up.pages.stepper-organization.address.info-error-cep')}
                        </Typography>
                    )}
                    {errorLenghtCEP && (
                        <Typography color='error'>
                            {t(
                                'sign-up.pages.stepper-organization.address.info-error-cep-min-char'
                            )}
                        </Typography>
                    )}
                </Grid>
                <Box mt={3} mb={3} width={1}>
                    <Typography variant='h6'>Contato</Typography>
                    <Divider component='h6' />
                </Box>
                <Grid container spacing={2}>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label='E-mail (Licitações)'
                            name='emailForNotifications'
                            value={form.values.emailForNotifications}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={
                                form.touched.emailForNotifications &&
                                Boolean(form.errors.emailForNotifications)
                            }
                            helperText={
                                form.touched.emailForNotifications &&
                                form.errors.emailForNotifications
                            }
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <PhoneInput
                            flags={flags}
                            initialValueFormat='national'
                            placeholder={t('term.phone')}
                            value={form.values.phoneNumber}
                            name='phoneNumber'
                            defaultCountry='BR'
                            onChange={(e) => {
                                form.setFieldValue('phoneNumber', e);
                            }}
                            disabled={disabled}
                            error={form.touched.phoneNumber && Boolean(form.errors.phoneNumber)}
                            helperText={form.touched.phoneNumber && form.errors.phoneNumber}
                            inputComponent={PersonPhoneInput}
                        />
                    </Grid>
                    <Grid item md={8} sm={12} xs={12}>
                        <TextField
                            fullWidth
                            label='E-mail (Financeiro)'
                            name='emailForBilling'
                            value={form.values.emailForBilling}
                            onChange={form.handleChange}
                            disabled={disabled}
                            error={
                                form.touched.emailForBilling && Boolean(form.errors.emailForBilling)
                            }
                            helperText={form.touched.emailForBilling && form.errors.emailForBilling}
                        />
                    </Grid>
                    <Grid item md={4} sm={12} xs={12}>
                        <PhoneInput
                            flags={flags}
                            initialValueFormat='national'
                            placeholder={t('term.billing-phone-number')}
                            value={form.values.billingPhoneNumber}
                            defaultCountry='BR'
                            onChange={(e) => {
                                form.setFieldValue('billingPhoneNumber', e);
                            }}
                            disabled={disabled}
                            error={
                                form.touched.billingPhoneNumber &&
                                Boolean(form.errors.billingPhoneNumber)
                            }
                            helperText={
                                form.touched.billingPhoneNumber && form.errors.billingPhoneNumber
                            }
                            inputComponent={PersonPhoneInput}
                        />
                    </Grid>
                    <Grid container alignContent='center' justifyContent='flex-end'>
                        <Box mt={2}>
                            <LoadingButton
                                variant='contained'
                                color='primary'
                                onClick={() => {
                                    form.submitForm();
                                }}
                                disabled={disabled}
                                size='small'
                                {...(updatingProvider
                                    ? {
                                          loading: {
                                              text: `${t('term.updating')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.save-data')}
                            </LoadingButton>
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default CompanyForm;

import { CircularProgress, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { auctionNoticeFilesRequests } from 'clients/manager/auction-notice-files.requests';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import { generatedDocuments } from 'clients/manager/generated-documents';
import { ProcessRelatoryType } from 'modules/process/interfaces/process-relatory-type';
import { addNotificationApiError, getBackofficeRedirectUrl } from 'common/utils';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiFile3Fill } from 'react-icons/ri';
import { AuctionNoticeFiles } from 'clients/manager/interfaces/auction-notice-files.interface';
import { GeneratedDocument } from 'clients/manager/interfaces/generated-documents.interface';
import { ProcessEnum } from 'clients/manager/interfaces/auction-notice.interface';
import { organizationMinutesRequests } from 'clients/manager/organization-minutes.requests';
import { useSelector } from 'react-redux';
import { AppState } from 'store';
import { AuctionTypeEnum } from 'common/enums';
import docs from './minutes.json';
import { ProcessDescriptionCard } from '../../styled';

interface DocsContainerProps {
    id: string;
    processType: AuctionTypeEnum;
}

type RenderDocType = {
    loading: boolean;
    data: {
        id: number;
        langKey?: string;
        description?: string;
        action?: string;
        openDoc: string;
        type?: string;
        processTypes?: string[];
    }[];
    langKey?: string;
};

const DocsContainer = ({ id, processType }: DocsContainerProps) => {
    const { t } = useTranslation();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const [mainDocuments, setMainDocuments] = useState<RenderDocType>({ loading: true, data: [] });
    const [minutes, setMinutes] = useState<RenderDocType['data']>([]);
    const [complementaryDocuments, setComplementaryDocuments] = useState<RenderDocType>({
        loading: true,
        data: [],
    });
    const { authUser } = useSelector((state: AppState) => state.authUserState);

    const formatComplementaryDocs = (data: AuctionNoticeFiles[]) => {
        const formattedData = data.map((doc) => ({
            id: doc.id,
            description: doc.description,
            openDoc: doc.pathFile,
            action: 'redirect',
        }));
        return formattedData;
    };

    const formatMainDocs = (data: GeneratedDocument[]) => {
        const formattedData = data.map((doc, idx) => ({
            id: idx,
            description: doc.fileDescription,
            openDoc: doc.url,
            action: 'redirect',
        }));
        return formattedData;
    };

    useEffect(() => {
        const getMainDocuments = async () => {
            try {
                setMainDocuments((prev) => ({ ...prev, loading: true }));
                const { data } = await generatedDocuments.listAuctionNoticeFiles({
                    params: { auctionId: Number(id) },
                });
                setMainDocuments((prev) => ({
                    ...prev,
                    data: formatMainDocs(data),
                    loading: false,
                }));
            } catch (error) {
                setMainDocuments((prev) => ({ ...prev, loading: false }));
                addNotificationApiError(error);
            }
        };

        const getComplementaryDocuments = async () => {
            try {
                setComplementaryDocuments((prev) => ({ ...prev, loading: true }));
                const { data } = await auctionNoticeFilesRequests.listAuctionNoticeFiles(
                    Number(id)
                );
                setComplementaryDocuments((prev) => ({
                    ...prev,
                    data: formatComplementaryDocs(data),
                    loading: false,
                }));
            } catch (error) {
                setComplementaryDocuments((prev) => ({ ...prev, loading: false }));
                addNotificationApiError(error);
            }
        };

        const getMinutes = async () => {
            const organizationMinutes = await organizationMinutesRequests.getProcessMinutes(
                {
                    processId: Number(id),
                    isOrganization: false,
                },
                authUser?.id === undefined
            );

            const formatedMinutes: RenderDocType['data'] = organizationMinutes.data.map((item) => {
                const { id, name, url } = item;
                return {
                    id,
                    langKey: name,
                    openDoc: url,
                    type: 'PDF',
                    action: 'customMinutes',
                    processTypes: ['trading', 'concurrence', 'auction', 'dispense'],
                };
            });

            const minutes = (docs as RenderDocType).data.filter((doc) => {
                if (doc?.processTypes?.includes(ProcessEnum[processType])) return true;
                return false;
            });

            setMinutes(organizationMinutes.data.length > 0 ? formatedMinutes : minutes);
        };

        getMainDocuments();
        getComplementaryDocuments();
        getMinutes();
    }, [authUser]);

    const documents = useMemo(
        () => [
            { langKey: 'bidding.process', ...mainDocuments },
            {
                langKey: 'bidding.process.section.documents.complementary-documents',
                ...complementaryDocuments,
            },
            {
                id: 1,
                langKey: 'bidding.process.section.documents.minutes',
                loading: false,
                data: minutes,
            },
        ],
        [mainDocuments, complementaryDocuments, minutes]
    );

    const handleOpenDefaultDocument = async (relatoryType) => {
        if (!id || !relatoryType) return;

        try {
            const token = await auctionNoticeRequests.getTokenToViewRelatory(
                Number(id),
                relatoryType
            );

            return window.open(`${backofficeRedirectUrl}/ver-documento?token=${token}`, '_blank');
        } catch (error) {
            addNotificationApiError(error);
        }
    };

    const handleOpenCustomMinutes = (documentTemplateId) => {      
        return window.open(
            `${backofficeRedirectUrl}/imprimir/?documentTemplateId=${documentTemplateId}&auctionNoticeId=${id}&forceGenerated=true`,
            '_blank'
        );
    };

    const handleClick = (action, openDoc, id) => {
        switch (action) {
            case 'redirect':
                window.open(openDoc, '_blank');
                break;
            case 'customMinutes':
                handleOpenCustomMinutes(id);
                break;
            default:
                handleOpenDefaultDocument(ProcessRelatoryType[openDoc]);
                break;
        }
    };

    return (
        <ProcessDescriptionCard variant='outlined'>
            <header>
                <RiFile3Fill size='1.5rem' />
                <h1>{t('bidding.process.section.documents')}</h1>
            </header>
            <section>
                <List dense>
                    {documents.map(({ langKey, data, loading }) => (
                        <Fragment key={langKey}>
                            {langKey && (data.length > 0 || loading) && (
                                <ListItem
                                    disableGutters
                                    style={{
                                        position: 'sticky',
                                        background: 'var(--background-color)',
                                        gap: '0.5rem',
                                        zIndex: 1,
                                        top: 0,
                                    }}
                                >
                                    {loading && <CircularProgress size={12} />}
                                    <ListItemText
                                        secondaryTypographyProps={{ color: 'secondary' }}
                                        primary={t(langKey)}
                                    />
                                </ListItem>
                            )}
                            <List disablePadding dense>
                                {data.map(
                                    (
                                        { langKey, description, openDoc, type, action, id },
                                        idx: number
                                    ) => (
                                        <ListItem
                                            key={`doc-${id}`}
                                            disableGutters
                                            button
                                            onClick={() => handleClick(action, openDoc, id)}
                                            divider={data.length > idx + 1}
                                        >
                                            <ListItemIcon
                                                style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <RiFile3Fill
                                                    color='var(--primary-color)'
                                                    size='1.75rem'
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                primaryTypographyProps={{
                                                    color: 'primary',
                                                }}
                                                secondaryTypographyProps={{
                                                    color: 'secondary',
                                                }}
                                                primary={langKey ? t(langKey) : description}
                                                secondary={
                                                    type ||
                                                    t('bidding.process.section.messages.attachment')
                                                }
                                            />
                                        </ListItem>
                                    )
                                )}
                            </List>
                        </Fragment>
                    ))}
                </List>
            </section>
        </ProcessDescriptionCard>
    );
};

export default DocsContainer;

import { doRequest, managerApiInstance } from '../api';
import { OkResponse } from '../interfaces/ok.interface';
import { PlainGenericResponse } from '../interfaces/paginated.interface';

interface CreateLotsAndItensData {
    auctionNoticeId: number;
    file: string;
    enterprise: string;
}
interface ExportLotsAndItensData {
    processId: number;
    enterprise: string;
    async: boolean;
    onlyWinners?: boolean;
}

const integrationsRequests = {
    createLotsAndItens: async (data: CreateLotsAndItensData) =>
        doRequest<PlainGenericResponse<OkResponse>>(
            managerApiInstance.post(`/integrations/imports/createLotsAndItens`, data)
        ),
    exportLotsAndItens: async (data: ExportLotsAndItensData) =>
        doRequest<any>(
            managerApiInstance.post(
                `/integrations/exports/auction-notice-lots`,
                {
                    params: data,
                },
                data.async
                    ? undefined
                    : {
                          responseType: 'arraybuffer',
                      }
            )
        ),
};

export { integrationsRequests };

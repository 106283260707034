import { FC, useState } from 'react';
import { Box, Grid, MenuItem, Typography } from '@material-ui/core';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import { useFormik } from 'formik';
import ReactDOM from 'react-dom';
import HelpIconLabel from 'modules/process/components/help-icon-label';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import {
    TypeOfBenefitValue,
    TypeOfItemsValue,
} from 'clients/manager/interfaces/auction-notice-lot.interface';
import {
    auctionNoticeLotRequests,
    BulkLotsUpdate,
} from 'clients/manager/auction-notice-lot.requests';
import LoadingButton from 'common/components/loading-button';
import { CurrencyInput } from 'common/components/currency';
import { addNotificationError, addNotificationSuccess, addNotificationWarning } from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import { ModalBulkChangesLotsProps } from './props';
import { useProcessFormContext } from '../../../../context/process-form.context';
import {
    disableField,
    getDecimalPlaces,
    processRules,
    processUtils,
} from '../../../../process-utils';
import TypeOfBenefit from '../../../type-benefit';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';

type BulkLotsUpdateForm = Omit<BulkLotsUpdate, 'auctionNoticeId'>;

const ModalBulkChangesLots: FC<ModalBulkChangesLotsProps> = ({
    auctionId,
    onClose,
    handleDefaultPage,
}) => {
    const [updating, setUpdating] = useState(false);

    const { t } = useTranslation();
    const { process, processForm, auctionTypeRules } = useProcessFormContext();
    const { formHasChanges, setProcessLotsAndItems } = useProcessLotsFormContext();
    const handleClickCancel = () => onClose();

    const getLotsFromProcess = async () => {
        if (!process?.id) {
            return;
        }

        try {
            const response = await auctionNoticeLotRequests.listLotsFromProcess({
                params: {
                    auctionId: process.id,
                },
            });
            setProcessLotsAndItems([...response.data]);
            setUpdating(false);
            onClose();
            handleDefaultPage([...response.data]);
            // eslint-disable-next-line no-empty
        } catch (error) {}
    };

    const bulkLotsUpdate = async (form: BulkLotsUpdateForm) => {
        for (const key in form) {
            if (isUndefined(form[key]) || form[key] === -1) {
                delete form[key];
            } else if (key === 'bidAmountDifference' && form[key] === 0) {
                delete form[key];
            }
        }

        if (!Object.keys(form).length) {
            onClose();
            addNotificationWarning({
                title: t('term.err'),
                message: t('process.components.error-no-updates'),
            });
        }

        try {
            setUpdating(true);
            const response = await auctionNoticeLotRequests.bulkLotsUpdate({
                ...form,
                auctionNoticeId: auctionId,
            });

            if (response.data?.ok) {
                await getLotsFromProcess();
                return addNotificationSuccess({
                    title: t('term.success'),
                    message: t('process.components.notification-all-fields-updates'),
                });
            }

            setUpdating(false);
            throw new Error();
        } catch (error) {
            setUpdating(false);
            addNotificationError({
                message: t('process.components.error-updates-fields-try-again'),
            });
        }
    };

    const form = useFormik<BulkLotsUpdateForm>({
        initialValues: {
            bidAmountDifference: undefined,
            typeOfItems: undefined,
            markIsRequired: undefined,
            showReferenceValue: undefined,
            typeOfBenefit: undefined,
            requireDocuments: undefined,
            forceSameDescription: undefined,
            propertyOrFurnitureCode: undefined,
        },
        onSubmit: (values) => {
            bulkLotsUpdate(values);
        },
    });

    const handleClickConfirmChanges = () => {
        form.submitForm();
    };

    const handleFieldChanged = (field: string, value: any) => form.setFieldValue(field, value);

    const getDefaultChecked = (field: string) =>
        form.values[field] === undefined ? -1 : form.values[field];

    const disabledFields =
        formHasChanges || !processActions.canEditLot(processForm?.values?.biddingStageId);

    const getMenuTypeOfItems = () => {
        if (processUtils.isPublicSale(processForm?.values)) {
            return [
                <MenuItem value={TypeOfItemsValue.properties}>{t('term.properties')}</MenuItem>,
                <MenuItem value={TypeOfItemsValue.furniture}>{t('term.furniture')}</MenuItem>,
            ];
        }
        return [
            <MenuItem value={TypeOfItemsValue.product}>{t('term.products')}</MenuItem>,
            <MenuItem value={TypeOfItemsValue.service}>{t('term.services')}</MenuItem>,
        ];
    };

    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={() => onClose()}
            header={<span>{t('process.components.bulk-changes')}</span>}
        >
            <Box p={2} width={1}>
                <Box width={1} mb={2}>
                    {formHasChanges ? (
                        <Alert severity='error'>
                            <Typography variant='body2'>
                                {t('info.bulk-update-with-changes')}
                            </Typography>
                        </Alert>
                    ) : (
                        <Alert severity='warning'>
                            <Typography variant='body2'>
                                {t('process.components.alert-changes')}
                            </Typography>
                        </Alert>
                    )}
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            color='primary'
                            disabled={disabledFields ?? true}
                            label={t('term.type-itens')}
                            defaultValue={getDefaultChecked('typeOfItems')}
                            onChange={(event) => {
                                handleFieldChanged('typeOfItems', event.target.value);
                            }}
                            variant='outlined'
                            select
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            {getMenuTypeOfItems()}
                        </TextField>
                    </Grid>
                    {(form.values.typeOfItems === TypeOfItemsValue.properties ||
                        form.values.typeOfItems === TypeOfItemsValue.furniture) && (
                        <Grid item xs={12} md={2}>
                            <TextField
                                fullWidth
                                color='primary'
                                defaultValue={form.values.propertyOrFurnitureCode}
                                label={
                                    form.values.typeOfItems === TypeOfItemsValue.properties
                                        ? t('term.estate-properties-code')
                                        : t('term.heritage-code')
                                }
                                onChange={(event) =>
                                    handleFieldChanged(
                                        'propertyOrFurnitureCode',
                                        event.target.value
                                    )
                                }
                                variant='outlined'
                                multiline
                            >
                                {getMenuTypeOfItems()}
                            </TextField>
                        </Grid>
                    )}
                    {process &&
                        processRules.visibleLotTypeOfBenefit(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} md={4}>
                                <TypeOfBenefit
                                    omitOptions={[
                                        TypeOfBenefitValue.reservedQuota,
                                        TypeOfBenefitValue.masterQuota,
                                    ]}
                                    showDoNotChanges
                                    defaultValue={getDefaultChecked('typeOfBenefit')}
                                    onChange={(typeOfBenefit) =>
                                        handleFieldChanged('typeOfBenefit', typeOfBenefit)
                                    }
                                    disabled={disabledFields}
                                    auctionType={process.auctionType}
                                    value={TypeOfBenefitValue.doNotChange}
                                />
                            </Grid>
                        )}
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            color='primary'
                            label={t('process.components.require-mark-manufacturer')}
                            variant='outlined'
                            select
                            disabled={disabledFields}
                            defaultValue={getDefaultChecked('markIsRequired')}
                            onChange={(event) => {
                                handleFieldChanged('markIsRequired', Boolean(event.target.value));
                            }}
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            <MenuItem value={1}>{t('term.yes')}</MenuItem>
                            <MenuItem value={0}>{t('term.no')}</MenuItem>
                        </TextField>
                    </Grid>
                    {processRules.visibleLotConfidentialValue(
                        auctionTypeRules,
                        processForm?.values
                    ) && (
                        <Grid item xs={12} md={4}>
                            <TextField
                                fullWidth
                                id='select'
                                disabled={disabledFields}
                                color='primary'
                                label={t('process.components.confidential-value')}
                                variant='outlined'
                                select
                                defaultValue={getDefaultChecked('showReferenceValue')}
                                onChange={(event) => {
                                    handleFieldChanged(
                                        'showReferenceValue',
                                        Boolean(event.target.value)
                                    );
                                }}
                            >
                                <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                                <MenuItem value={1}>{t('term.yes')}</MenuItem>
                                <MenuItem value={0}>{t('term.no')}</MenuItem>
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12} md={4}>
                        <HelpIconLabel title={t('info.force-same-description.help')}>
                            <TextField
                                fullWidth
                                id='select'
                                disabled={disabledFields}
                                color='primary'
                                label={t('info.force-same-description')}
                                variant='outlined'
                                select
                                defaultValue={getDefaultChecked('forceSameDescription')}
                                onChange={(event) => {
                                    handleFieldChanged(
                                        'forceSameDescription',
                                        Boolean(event.target.value)
                                    );
                                }}
                            >
                                <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                                <MenuItem value={1}>{t('term.yes')}</MenuItem>
                                <MenuItem value={0}>{t('term.no')}</MenuItem>
                            </TextField>
                        </HelpIconLabel>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            id='select'
                            disabled={disabledFields}
                            color='primary'
                            label={t('info.require-documents')}
                            variant='outlined'
                            select
                            defaultValue={getDefaultChecked('requireDocuments')}
                            onChange={(event) => {
                                handleFieldChanged('requireDocuments', Boolean(event.target.value));
                            }}
                        >
                            <MenuItem value={-1}>{t('term.do-not-change')}</MenuItem>
                            <MenuItem value={1}>{t('term.yes')}</MenuItem>
                            <MenuItem value={0}>{t('term.no')}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <CurrencyInput
                            allowNegative={false}
                            prefix='R$ '
                            decimalSeparator=','
                            disabled={disableField('bidAmountDifference', processForm?.values)}
                            precision={getDecimalPlaces(process?.decimalPlaces)}
                            thousandSeparator='.'
                            label={t('process.components.value-between-bids')}
                            variant='outlined'
                            value={form.values.bidAmountDifference ?? 0}
                            defaultValue={
                                form.values.bidAmountDifference ||
                                auctionTypeRules?.generalSettings?.cadastro?.intervaloEntreLances
                            }
                            color='primary'
                            onChange={(_, value: number) =>
                                handleFieldChanged('bidAmountDifference', value)
                            }
                            component={TextField}
                        />
                    </Grid>
                </Grid>
                <Box display='flex' justifyContent='flex-end' mt={3}>
                    <Button
                        variant='outlined'
                        color='primary'
                        title={t('term.cancel')}
                        onClick={handleClickCancel}
                        size='small'
                        style={{ marginRight: '16px' }}
                    >
                        {t('term.cancel')}
                    </Button>
                    <LoadingButton
                        variant='contained'
                        color='primary'
                        title={t('process.components.apply-alterations-all-proccess')}
                        onClick={handleClickConfirmChanges}
                        size='small'
                        disabled={Object.values(form.values).every((value) => isUndefined(value))}
                        {...(updating
                            ? {
                                  loading: {
                                      text: `${t('term.updating')}..`,
                                  },
                              }
                            : {})}
                    >
                        {t('term.apply-changes')}
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    );
};

export default ModalBulkChangesLots;

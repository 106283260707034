import { FC, useState, memo, useCallback, useEffect } from 'react';
import { Box, MenuItem, Typography } from '@material-ui/core';
import { Button, Modal, ModalPosition, TextField } from 'common/components';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { integrationsRequests } from 'clients/manager/integrations.requests';
import {
    addNotificationError,
    addNotificationSuccess,
    addNotificationApiError,
    getBackofficeRedirectUrl,
    getBase64FromFile,
    getMd5Hash,
    timeout,
} from 'common/utils';
import { auctionNoticeLotRequests } from 'clients/manager/auction-notice-lot.requests';
import LoadingButton from 'common/components/loading-button';
import { formatExport } from 'common/constants/format-export.static';
import { CONSTANTS } from 'common/constants';
import { processUtils } from 'modules/process/process-utils';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { processActions } from 'modules/process/process-actions';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { useProcessLotsFormContext } from '../../../../context/process-lots-form.context';
import { ModalImportExportProps } from './props';
import ModalImportLotsAndItens from './modal-import-lots';
import ModalExportLotsAndItens from './modal-export-lots';

const ModalImportExport: FC<ModalImportExportProps> = ({
    auctionId,
    onClose,
    type,
    handleDefaultPage,
}) => {
    const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
    const [erpType, setErpType] = useState<number | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [onlyWinners, setOnlyWinners] = useState(false);
    const [async, setAsync] = useState(false);
    const [fileTransferTypes, setFileTransferTypes] = useState<string[]>([]);
    const [descriptionFileTypes, setDescriptionFileTypes] = useState<string[]>([]);
    const { t } = useTranslation();
    const { processForm, process } = useProcessFormContext();
    const { setProcessLotsAndItems } = useProcessLotsFormContext();
    const backofficeRedirectUrl = getBackofficeRedirectUrl();
    const isImport = type === 'import';
    const isExport = type === 'export';
    const excelTypeFile = 'Excel';

    const getDropZoneSettings = useCallback(
        () => ({
            multiple: false,
            maxSize: CONSTANTS.defaultFileMaxSize,
            onDropRejected: (files) => {
                addNotificationError({
                    title: t('info.invalid-files'),
                    message: t('info.files-were-not-accepted', {
                        value: String(files.length).padStart(2, '0'),
                    }),
                });
            },
        }),
        []
    );

    const dropzoneProps = useDropzone(getDropZoneSettings());
    const { acceptedFiles } = dropzoneProps;

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setSelectedFile(acceptedFiles[0]);
        }
    }, [acceptedFiles]);

    useEffect(() => {
        if (process?.integrationsData) {
            const uniqueArray = _.uniqBy(process.integrationsData, (obj) => obj.integration);
            const filterData = uniqueArray.find(
                ({ categoryId, fileTransferType }) => categoryId === 2 && fileTransferType !== null
            );

            if (filterData?.integration) {
                setFileTransferTypes([excelTypeFile, filterData.fileTransferType]);
                setDescriptionFileTypes([excelTypeFile, filterData.description]);
            } else {
                setFileTransferTypes([excelTypeFile]);
                setDescriptionFileTypes([excelTypeFile]);
            }
        }
    }, []);

    const handleClickCancel = () => {
        onClose();
    };

    const getLotsFromProcess = async () => {
        try {
            const response = await auctionNoticeLotRequests.listLotsFromProcess({
                params: {
                    auctionId: auctionId,
                },
            });

            setProcessLotsAndItems(response.data);
            if (handleDefaultPage) handleDefaultPage(response.data);
        } catch (error) {
            window.location.reload();
        }
    };

    const getfileTransferTypeObject = () =>
        fileTransferTypes.reduce((acc, curr, index) => {
            acc = { [index + 1]: curr.toLowerCase(), ...acc };
            return acc;
        }, {});

    const handleClickImport = async () => {
        if (!erpType || !selectedFile) {
            return;
        }

        try {
            setLoading(true);
            const optionsMap = getfileTransferTypeObject();
            const base64 = await getBase64FromFile(selectedFile);
            const response = await integrationsRequests.createLotsAndItens({
                auctionNoticeId: auctionId,
                enterprise: optionsMap[erpType],
                file: base64,
            });

            if (response.data?.ok) {
                ReactDOM.unstable_batchedUpdates(() => {
                    processForm?.setFieldValue('integrationFileModel', optionsMap[erpType]);
                    setLoading(false);
                    onClose();
                    addNotificationSuccess({
                        message: t('process.components.notification-lots-importeds-success'),
                    });
                    timeout(() => getLotsFromProcess(), 100);
                });
            }
        } catch (error) {
            setLoading(false);
            addNotificationApiError(error);
        }
    };

    const exportByExcel = () => {
        return window.open(
            `${backofficeRedirectUrl}/exportar/?auctionNoticeId=${process?.id}&onlyWinner=${onlyWinners}`,
            '_blank'
        );
    };

    const exportByIntegration = async () => {
        if (erpType && process?.id) {
            const integrationData = process.integrationsData?.find(
                ({ categoryId, fileTransferType }) => categoryId === 2 && fileTransferType !== null
            );
            const response = await integrationsRequests.exportLotsAndItens({
                async,
                enterprise: integrationData?.fileTransferType || '',
                processId: process.id,
                onlyWinners,
            });
            if (response.status === 'success' && async) {
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.export.process.generating-file'),
                });
                return;
            }
            const extension: string = formatExport[integrationData?.integration || ''] || '.txt';
            const fileName = `${integrationData?.integration}_${getMd5Hash('')}${extension}`;
            const fileUrl = window.URL.createObjectURL(
                new Blob([response as any], { type: 'application/octet-stream' })
            );
            const downloadLink = document.createElement('a');
            downloadLink.href = fileUrl;
            downloadLink.download = fileName;
            downloadLink.click();
            addNotificationSuccess({
                title: t('term.success'),
                message: t('info.success', {
                    action: 'Download',
                }),
            });
        }
    };
    const handleClickExport = async () => {
        const isAccreditation = processUtils.isAccreditationProcess(process);
        if (!erpType) {
            return;
        }

        try {
            if (erpType === 1) {
                exportByExcel();
                addNotificationSuccess({
                    title: t('term.success'),
                    message: t('info.success', {
                        action: 'Download',
                    }),
                });
                return;
            }
            setLoading(true);
            await exportByIntegration();
            setLoading(false);
            onClose();
        } catch (error) {
            setLoading(false);
            addNotificationError(
                isAccreditation
                    ? {
                          message: t('process.components.accreditation-export-only-excel'),
                      }
                    : {
                          message: t('process.components.error-exported-arq-try-again'),
                      }
            );
        }
    };

    const confirmButtonDisabledDialog =
        processForm?.values && processActions.inDraft(processForm?.values.biddingStageId);
    const confirmButtonText = isImport ? t('term.import') : t('term.export');
    const confirmButtonTextLoading = isImport ? t('term.importing') : t('term.exporting');
    const confirmButtonTitle = isImport ? t('term.import-data') : t('term.export-data');
    const selectLabel = isImport
        ? t('process.components.type-import')
        : t('process.components.type-export');
    const infoLabel = isImport
        ? t('process.components.select-system-management-import')
        : t('process.components.select-system-management-export');
    return (
        <Modal
            position={ModalPosition.center}
            open
            onClose={() => onClose()}
            header={<span>{t('process.components.integration-management-system')}</span>}
        >
            <Box p={2}>
                {isImport && (
                    <Alert severity='warning'>
                        <Typography variant='body2'>{t('info.import-external-lots')}</Typography>
                    </Alert>
                )}

                <Box mt={2}>
                    <Typography>{infoLabel}</Typography>
                </Box>
                <Box mt={2} mb={1}>
                    <TextField
                        fullWidth
                        id='select'
                        color='primary'
                        label={selectLabel}
                        onChange={(event) => {
                            setErpType(event.target.value as unknown as number);
                        }}
                        variant='outlined'
                        select
                        defaultValue={erpType}
                    >
                        {descriptionFileTypes.map((integration, index) => (
                            <MenuItem value={index + 1}>{integration}</MenuItem>
                        ))}
                    </TextField>
                </Box>
                {isImport ? (
                    <ModalImportLotsAndItens
                        erpType={erpType}
                        dropzoneProps={dropzoneProps}
                        selectedFile={selectedFile}
                        setSelectedFile={setSelectedFile}
                    />
                ) : (
                    <ModalExportLotsAndItens setOnlyWinners={setOnlyWinners} setAsync={setAsync} />
                )}
                <Box display='flex' justifyContent='flex-end' mt={selectedFile ? 2 : 0}>
                    <Button
                        variant='outlined'
                        color='primary'
                        title={t('term.cancel')}
                        onClick={handleClickCancel}
                        size='small'
                        style={{ marginRight: '16px' }}
                    >
                        {t('term.cancel')}
                    </Button>
                    <ConfirmDialog
                        disabled={confirmButtonDisabledDialog || isExport}
                        title={t('term.import-alert')}
                        message={t('term.import-confirm-dialog')}
                        onConfirm={isImport ? handleClickImport : handleClickExport}
                    >
                        <LoadingButton
                            variant='contained'
                            color='primary'
                            title={confirmButtonTitle}
                            size='small'
                            disabled={isImport ? !selectedFile || !erpType : !erpType}
                            {...(loading
                                ? {
                                      loading: {
                                          text: confirmButtonTextLoading,
                                      },
                                  }
                                : {})}
                        >
                            {confirmButtonText}
                        </LoadingButton>
                    </ConfirmDialog>
                </Box>
            </Box>
        </Modal>
    );
};

export default memo(ModalImportExport);

import { Box, Grid, Paper, Checkbox, FormControlLabel, MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import { debounce } from 'lodash';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatAuctionNumber } from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import ExpandedContentCard from '../../../expand-content-card';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { getGeneralDataFormLabels } from '../../../../context/schema/general-data-form-schema';
import CreatableTags from '../../../creatable-tags';
import ProcessContentHeader from '../process-content-header';
import SelectJudgmentCriterion from '../select-judgment-criterion';
import { ProcessGeneralDataProps } from './props';
import AuctionNumberField from '../auction-number-field';
import AccreditationNumberField from '../accreditation-number-field';
import PhaseReversalField from '../phase-reversal-field';
import DisputeDurationField from '../dispute-duration-field';
import MethodDisputeField from '../method-dispute-field';
import PriceRegistrationField from '../price-registration-field';
import TypeValueBidField from '../type-value-bid-field';
import DecimalPlacesField from '../decimal-places-field';
import RandomEndTimeField from '../random-end-time-field';
import ExpirationDateRegistration from '../expiration-date-registration-field';
import { disableField, processRules, processUtils } from '../../../../process-utils';
import AccreditationDocumentsDate from '../accreditation-documents-date';
import UnenforceableNumberField from '../unenforceable-number-field';

const ProcessGeneralData: FC<ProcessGeneralDataProps> = ({ disabledExpand }) => {
    const { processForm, auctionTypeRules, process } = useProcessFormContext();
    const { t } = useTranslation();

    const handleChangeDefault = debounce(
        (event: React.ChangeEvent<HTMLInputElement>) =>
            processForm?.setFieldValue(event.target.name, event.target.value),
        300
    );

    const handleChangeIsRideAllowed = debounce(
        (event: React.ChangeEvent<any>) =>
            processForm?.setFieldValue('isRideAllowed', Number(event.target.checked)),
        300
    );

    const handleChangeBudgetAllocationNumber = debounce(
        (values: string[]) =>
            processForm?.setFieldValue('budgetAllocationNumber', values.join(',')),
        300
    );

    const budgetAllocationValues = processForm?.values.budgetAllocationNumber?.length
        ? processForm?.values.budgetAllocationNumber?.split(',')
        : [];

    const updateDebounceProcess = useCallback(
        debounce((event, field) => {
            if (field === 'auctionNumber') {
                if (
                    !auctionTypeRules?.generalSettings?.cadastro?.numeroProcessoAutomatico &&
                    auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroAutomatico
                ) {
                    processForm?.setFieldValue(field, event.target.value);
                } else {
                    const value: string = event.target.value.replace(/\D/g, '');
                    processForm?.setFieldValue(field, formatAuctionNumber(value));
                }
            } else if (field === 'accreditationNumber') {
                if (
                    !auctionTypeRules?.generalSettings?.cadastro?.numeroModalidadeAutomatico &&
                    auctionTypeRules?.generalSettings?.cadastro?.formulaNumeroModalidadeAutomatico
                ) {
                    processForm?.setFieldValue(field, event.target.value);
                } else {
                    const value: string = event.target.value.replace(/\D/g, '');
                    processForm?.setFieldValue(field, formatAuctionNumber(value));
                }
            } else {
                const value: string = event.target.value.replace(/\D/g, '');
                processForm?.setFieldValue(field, formatAuctionNumber(value));
            }
        }, 300),
        [processForm]
    );

    return (
        <Box mt={2} mb={2}>
            <Paper variant='outlined' elevation={2}>
                <ExpandedContentCard
                    disabled={disabledExpand}
                    defaultExpanded={false}
                    header={
                        <ProcessContentHeader
                            title={t('term.general-data')}
                            partialProcessFormLabels={getGeneralDataFormLabels(processForm?.values)}
                        />
                    }
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} lg={2} md={4}>
                            <AuctionNumberField updateDebounceProcess={updateDebounceProcess} />
                        </Grid>
                        <Grid item xs={12} lg={2} md={4}>
                            <AccreditationNumberField
                                updateDebounceProcess={updateDebounceProcess}
                            />
                        </Grid>
                        {processRules.visibleUnenforceableNumber(processForm?.values) ? (
                            <Grid item xs={12} lg={2} md={3}>
                                <UnenforceableNumberField
                                    updateDebounceProcess={updateDebounceProcess}
                                />
                            </Grid>
                        ) : null}
                        {processRules.visibleRangeRegionParticipation(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} lg={3} md={4}>
                                <TextField
                                    fullWidth
                                    name='rangeRegionParticipation'
                                    color='primary'
                                    label={t('term.regional-preference')}
                                    onChange={handleChangeDefault}
                                    variant='outlined'
                                    select
                                    defaultValue={processForm?.values.rangeRegionParticipation}
                                    disabled={disableField('rangeRegionParticipation', process)}
                                >
                                    <MenuItem value={0}>{t('info.not-apply')}</MenuItem>
                                    <MenuItem value={1}>Regional</MenuItem>
                                    <MenuItem value={2}>Local</MenuItem>
                                </TextField>
                            </Grid>
                        )}
                        {processRules.visibleMethodDispute(processForm?.values) ? (
                            <Grid item xs={12} lg={2} md={3}>
                                <MethodDisputeField />
                            </Grid>
                        ) : null}
                        {processRules.visibleDisputeDuration(processForm?.values) ? (
                            <Grid item xs={12} lg={3} md={4}>
                                <DisputeDurationField />
                            </Grid>
                        ) : null}
                        {processRules.visibleJudgmentCriterion(processForm?.values) ? (
                            <Grid item xs={12} lg={3} md={4}>
                                <SelectJudgmentCriterion />
                            </Grid>
                        ) : null}
                        {processRules.visibleTypeValueBid(processForm?.values) ? (
                            <Grid item xs={12} lg={3} md={3}>
                                <TypeValueBidField handleChangeDefault={handleChangeDefault} />
                            </Grid>
                        ) : null}
                        {processRules.visibleBudgetAllocationNumber(processForm?.values) && (
                            <Grid item xs={12} lg={4} md={4}>
                                <CreatableTags
                                    value={budgetAllocationValues}
                                    label={t('term.budget-appropriation')}
                                    onChange={handleChangeBudgetAllocationNumber}
                                />
                            </Grid>
                        )}
                        {processRules.visibleDecimalPlaces(processForm?.values) && (
                            <Grid item xs={12} lg={2} md={2}>
                                <DecimalPlacesField handleChangeDefault={handleChangeDefault} />
                            </Grid>
                        )}
                        {processRules.visibleIsPhaseReversal(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} lg={3} md={4}>
                                <PhaseReversalField handleChangeDefault={handleChangeDefault} />
                            </Grid>
                        )}
                        {processRules.visibleRandomEndTime(
                            auctionTypeRules,
                            processForm?.values
                        ) && (
                            <Grid item xs={12} lg={3} md={3}>
                                <RandomEndTimeField handleChangeDefault={handleChangeDefault} />
                            </Grid>
                        )}
                        {processUtils.isAccreditationProcess(processForm?.values) && (
                            <AccreditationDocumentsDate />
                        )}
                        {processRules.visiblePriceRegistration(processForm?.values) ? (
                            <>
                                <Grid item xs={12} lg={3} md={3}>
                                    <PriceRegistrationField />
                                </Grid>
                                {processForm?.values.itsPriceRegistration ? (
                                    <>
                                        <Grid item xs={12} lg={3} md={3}>
                                            <ExpirationDateRegistration
                                                handleChangeDefault={handleChangeDefault}
                                            />
                                        </Grid>
                                        <Grid item xs={12} lg={3} md={3}>
                                            <Box height={1} display='flex' alignItems='center'>
                                                <FormControlLabel
                                                    onChange={handleChangeIsRideAllowed}
                                                    name='isRideAllowed'
                                                    control={
                                                        <Checkbox
                                                            disabled={processActions.cannotEditUntilDispute(
                                                                process
                                                            )}
                                                            defaultChecked={Boolean(
                                                                processForm?.values.isRideAllowed
                                                            )}
                                                        />
                                                    }
                                                    label={t('term.ride-is-allowed')}
                                                />
                                            </Box>
                                        </Grid>
                                    </>
                                ) : null}
                            </>
                        ) : null}
                    </Grid>
                </ExpandedContentCard>
            </Paper>
        </Box>
    );
};

export default ProcessGeneralData;

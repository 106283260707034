import { memo, useState } from 'react';
import { processUtils } from 'modules/process/process-utils';
import { useTranslation } from 'react-i18next';
import { auctionNoticeRequests } from 'clients/manager/auction-notice.requests';
import {
    addNotificationApiError,
    addNotificationSuccess,
    addNotificationWarning,
    sleep,
} from 'common/utils';
import { useHistory } from 'react-router';
import { providerRequests } from 'clients/manager/provider.requests';
import { AuctionType, AuctionNotice } from 'clients/manager/interfaces/auction-notice.interface';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import { useProcessFormContext } from '../../context/process-form.context';
import ButtonSaveProcessView from './button-save-process-view';

const ButtonSaveProcess = () => {
    const { processForm, setProcessBkpBeforeSave, requiredDocumentsTypesIds } = useProcessFormContext();
    const isEdittingProcessForm = !!processForm?.values?.id;
    const isImportedByManagementSystem = processUtils.isImportedByManagementSystem(
        processForm?.values
    );

    const history = useHistory();
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const createProcess = async (process: AuctionNotice) => {
        try {
            const response = await auctionNoticeRequests.createProcess(process);
            const processId = response.data?.id;

            await sleep(500);

            if (processId) {
                history.push({
                    pathname: `/processos/${processId}`,
                    search: `?aba=dados`,
                });

                addNotificationSuccess({
                    title: t('process.components.process-created'),
                    message: t('process.components.process-created-success'),
                });
                return;
            }

            throw new Error(t('process.components.process-not-created'));
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setLoading(false);
        }
    };

    const handleUpdateProcess = async (processId: number) => {
        const afterUpdateProcess = await auctionNoticeRequests.getAuctionNotice(processId);
        if (afterUpdateProcess?.data?.biddingStageId) {
            processForm?.setFieldValue('biddingStageId', afterUpdateProcess.data.biddingStageId);
        }
    };

    const updateProcess = async (process: AuctionNotice) => {
        try {
            const response = await auctionNoticeRequests.updateProcess({
                ...process,
                requiredDocumentsTypesIds: requiredDocumentsTypesIds,
                globalAmount: parseFloat(String(process?.globalAmount)),
            });
            await handleUpdateProcess(process.id);
            if (response.data) {
                if (response.data.inviteProcess?.id && processForm) {
                    processForm.setFieldValue('participantId', response.data.inviteProcess?.id);
                    setProcessBkpBeforeSave({
                        ...processForm?.values,
                        participantId: response.data.inviteProcess?.id,
                    });
                } else {
                    setProcessBkpBeforeSave(processForm?.values);
                }
                addNotificationSuccess({
                    title: t('info.updated-process'),
                    message: t('info.updated-process'),
                });
            }
        } catch (err) {
            addNotificationApiError(err);
        } finally {
            setLoading(false);
        }
    };

    const verifyData = async (process: AuctionNotice) => {
        try {
            const provider = await providerRequests.getProviderByDocNumber(
                process.participantDocument
            );

            if (!provider.data && (!process.participantEmail || !process.participantName)) {
                addNotificationWarning({
                    message: `${t('term.fill-fields')}`,
                    title: `${t('term.required-fields')}`,
                });
                setLoading(false);
                return false;
            }

            return true;
        } catch (err) {
            addNotificationApiError(err);
        }
    };

    const handleOnClick = async () => {
        setLoading(true);

        if (!processForm?.values) {
            return;
        }

        if (processForm?.values.auctionType === AuctionType.unenforceability) {
            if (processForm?.values.participantDocument) {
                const verify = await verifyData(processForm?.values);
                if (!verify) {
                    setLoading(false);
                    return;
                }
            } else {
                addNotificationWarning({
                    message: `${t('term.participantDocument-required')}`,
                    title: `${t('term.required-fields')}`,
                });
                setLoading(false);
                return;
            }
        }

        if (isEdittingProcessForm) {
            updateProcess(processForm.values);
        } else {
            createProcess(processForm.values);
        }
    };

    if (
        processUtils.disabledProcess(processForm?.values) ||
        processUtils.finishedProcess(processForm?.values)
    ) {
        return null;
    }

    if (isEdittingProcessForm && isImportedByManagementSystem) {
        return (
            <ConfirmDialog
                title={t('organization-users.pages.components.alert')}
                message={t('process.components.message-confirm-update-process-integration')}
                onConfirm={handleOnClick}
            >
                <ButtonSaveProcessView loading={loading} isEditting={isEdittingProcessForm} />
            </ConfirmDialog>
        );
    }

    return (
        <ButtonSaveProcessView
            loading={loading}
            onClick={handleOnClick}
            isEditting={isEdittingProcessForm}
        />
    );
};

export default memo(ButtonSaveProcess);

import { Box, Grid, Tabs } from '@material-ui/core';
import React, { FC, useCallback } from 'react';
import ButtonSaveProcess from '../button-save-process';
import { ContentTabsProps } from './props';
import { CustomTab } from './styles';

const ContentTabs: FC<ContentTabsProps> = ({
    tabs,
    currentTab,
    onTabChanged,
    visibleUpdateProcess,
    fixed = false,
}) => {
    const getTabs = useCallback(() => tabs, [tabs]);

    const handleChange = (_: React.ChangeEvent<{}>, tabIndex: number) => {
        const tab = getTabs()[tabIndex + 1];

        if (tab?.disabled) {
            return;
        }
        onTabChanged(tabIndex + 1);
    };

    return (
        <Grid
            container
            {...(fixed
                ? {
                      style: {
                          position: 'sticky',
                          top: '-16px',
                          zIndex: 99,
                          backgroundColor: '#FFF',
                      },
                  }
                : {})}
        >
            <Box width={1} display='flex' justifyContent='space-between' alignItems='center'>
                <Tabs
                    value={currentTab - 1}
                    onChange={handleChange}
                    indicatorColor='primary'
                    textColor='primary'
                    variant='scrollable'
                    scrollButtons='auto'
                >
                    {Object.keys(getTabs()).map((key) => {
                        const tab = tabs[key];
                        return <CustomTab key={key} label={tab.title} />;
                    })}
                </Tabs>
                {visibleUpdateProcess && <ButtonSaveProcess />}
            </Box>
        </Grid>
    );
};

export default ContentTabs;

import i18n from 'common/i18n';

export const formatLotName = (item: number, quotaId?: number) => {
    const formattedItem = String(item).padStart(2, '0');

    if (quotaId) {
        return `${formattedItem} (${i18n.t('term.quota')})`;
    }

    return formattedItem;
};

import {
    Box,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Checkbox,
    Typography,
    IconButton,
    TableFooter,
} from '@material-ui/core';
import { Button } from 'common/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { RiStethoscopeLine } from 'react-icons/ri';
import LoadingButton from 'common/components/loading-button';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmDialog } from 'common/components/confirm-dialog';
import ExpandedContentCard from 'modules/process/components/expand-content-card';
import { useProcessFormContext } from 'modules/process/context/process-form.context';
import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { formatCurrency } from 'common/utils';
import { processActions } from 'modules/process/process-actions';
import LoadingModal from 'common/components/modal-loading';
import { TypeOfBenefitValue } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { processRules, processUtils } from 'modules/process/process-utils';
import { useStyles } from './styles';
import ProcessLotAdvancedInfo from '../process-lot-advanced-info';
import MultipleWinners from '../multiple-winners';
import LotItemsTableHead from './lot-items-table-head';
import RenderCell from './cells';
import SearchSusCodeModal from './components/search-sus-code-modal';
import ProcessLotHeader from './components/process-lot-header';
import { getColumns } from './columns';
import { rowsPerPageOptions } from './process-lot';
import { ProcessLotViewProps } from './props';

const ProcessLotView: React.FC<ProcessLotViewProps> = ({
    lotHasChanged,
    canSaveLot,
    canEditLot,
    canEditLotItems,
    canCreateLotQuota,
    canCreateLotItem,
    disableCreateLotItem,
    canDeleteLotItems,
    visibleAdvancedInfoBlock,
    visibleAdvancedInfo,
    showSus,
    expandedLot,
    setExpandedLot,
    creatingLotItem,
    savingLot,
    lotItem,
    oppenedSusModal,
    setOppenedSusModal,
    page,
    rowsPerPage,
    lot,
    selectedItems,
    values,
    handleSelectAllClick,
    isSelected,
    handleCheckItem,
    handleChangePage,
    handleChangeRowsPerPage,
    handleClickCreateItem,
    handleDeleteItems,
    // @TODO: 11/06/2024 removemos o botão de salvamento individual, pois estava gerando bugs
    // onClickSaveLot,
    onClickAdvancedInfo,
    onClickDeleteLot,
    onClickUpdateLotQuota,
    loading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const { processForm, auctionTypeRules } = useProcessFormContext();
    const { processLotsForm, setFieldValue } = useProcessLotsFormContext();
    const selectedFormValue = processLotsForm.values.lots.find((formLot) => formLot.id === lot.id);
    const canEditBidAmountDifference = processActions.canEditUntilDispute(
        processForm?.values?.biddingStageId
    );
    const showCrudButtons = canEditLot || canEditBidAmountDifference || canCreateLotItem || canDeleteLotItems;
    const columns = getColumns();
    const hasQuota =
        lot.id !== null &&
        processLotsForm.values.lots.find((formLot) => formLot.quotaId === lot.id);
    const isQuota = lot.quotaId !== null;

    const calculateTotalSum = () => {
        const hasItemsKits = processRules.visibleLotItemsKits(
            auctionTypeRules,
            processForm?.values
        );

        const totalLotValue = values.items.reduce(
            (sum, item) => sum + (item.amount * item.referenceValue || 0),
            0
        );

        if (hasItemsKits) {
            return totalLotValue * (values?.itemsKits || 1);
        }

        return totalLotValue;
    };

    const renderAdvancedInfo = () => {
        if (visibleAdvancedInfo && visibleAdvancedInfoBlock) {
            return (
                <>
                    <ProcessLotAdvancedInfo
                        lot={lot}
                        disabled={!canEditLot}
                        onClickUpdateLotQuota={onClickUpdateLotQuota}
                    />
                    {lot.allowMultipleWinner && <MultipleWinners lot={lot} />}
                </>
            );
        }
    };

    const messageToConfirmDeleteItem = processUtils.isAlreadyPublished(
        processForm?.values?.biddingStageId
    )
        ? t('process.components.message-confirm-delete-item-description')
        : '';

    const getCardContent = () => (
        <Box width={1}>
            {renderAdvancedInfo()}
            <TableContainer>
                <Table
                    classes={{ root: classes.table }}
                    stickyHeader
                    aria-label={t('process.components.table-lots-itens')}
                >
                    <LotItemsTableHead
                        numSelected={selectedItems.length}
                        onSelectAllClick={handleSelectAllClick}
                        rowCount={values.items.length}
                        showSelectAllRows={canEditLotItems}
                    />
                    <TableBody>
                        {values.items
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((item, i) => {
                                const index = i + page * rowsPerPage;

                                const isItemSelected = isSelected(index);
                                const labelId = `table-checkbox-${index}`;
                                const susOption = {
                                    susText: selectedFormValue?.items?.[index]?.susText ?? '',
                                    susCode: selectedFormValue?.items?.[index]?.susCode ?? '',
                                };

                                return (
                                    <React.Fragment key={`head-${index}`}>
                                        <TableRow
                                            className={
                                                showSus
                                                    ? classes.tableRowBorderNone
                                                    : classes.tableRow
                                            }
                                            role='checkbox'
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            selected={isItemSelected}
                                        >
                                            {canEditLotItems && (
                                                <TableCell
                                                    padding='checkbox'
                                                    className={classes.borderNone}
                                                >
                                                    <Checkbox
                                                        onClick={(event) =>
                                                            handleCheckItem(event, index)
                                                        }
                                                        checked={isItemSelected}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                            )}
                                            {columns.map((column) => (
                                                <RenderCell
                                                    key={`cell-${item.id}-${column.id}`}
                                                    index={index}
                                                    column={column}
                                                    item={item}
                                                    decimalPlaces={
                                                        processForm?.values?.decimalPlaces ?? 2
                                                    }
                                                    canEditFields={canEditLotItems}
                                                    onChange={(value) =>
                                                        setFieldValue(
                                                            lot,
                                                            `items[${index}].${column.id}`,
                                                            value
                                                        )
                                                    }
                                                    onExternalIdChange={(value) =>
                                                        setFieldValue(
                                                            lot,
                                                            `items[${index}].externalItemId`,
                                                            value
                                                        )
                                                    }
                                                    showSus={showSus}
                                                />
                                            ))}
                                        </TableRow>
                                        {showSus && (
                                            <TableRow>
                                                <TableCell>
                                                    <IconButton
                                                        disabled={!canEditLotItems}
                                                        onClick={() => setOppenedSusModal(item.id)}
                                                    >
                                                        <RiStethoscopeLine />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell colSpan={6}>
                                                    {susOption?.susCode ? (
                                                        <Typography>
                                                            {susOption?.susCode} -{' '}
                                                            {
                                                                selectedFormValue?.items?.[index]
                                                                    ?.susText
                                                            }
                                                        </Typography>
                                                    ) : (
                                                        <Typography>
                                                            {t('process.components.sus-code')}
                                                        </Typography>
                                                    )}
                                                </TableCell>
                                                <SearchSusCodeModal
                                                    open={oppenedSusModal === item.id}
                                                    value={susOption ?? null}
                                                    onClose={() => setOppenedSusModal(undefined)}
                                                    onChange={async (value) => {
                                                        setFieldValue(
                                                            lot,
                                                            `items[${index}].susCode`,
                                                            value?.susCode
                                                        );
                                                        setFieldValue(
                                                            lot,
                                                            `items[${index}].susText`,
                                                            value?.susText
                                                        );
                                                        setOppenedSusModal(undefined);
                                                    }}
                                                />
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TableCell colSpan={isQuota ? 4 : 5} className={classes.textAlignRight}>
                                <Typography variant='h6' className={classes.valuesTextFontSize}>
                                    {`${t('term.total-value-lote')}:`}
                                </Typography>
                            </TableCell>
                            <TableCell className={classes.textAlignRight}>
                                <Typography
                                    variant='subtitle1'
                                    className={classes.valuesTextFontSize}
                                >
                                    {formatCurrency(
                                        calculateTotalSum(),
                                        processForm?.values?.decimalPlaces ?? 2
                                    )}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            {values.items.length > rowsPerPageOptions[0] ? (
                <TablePagination
                    rowsPerPageOptions={rowsPerPageOptions}
                    component='div'
                    count={values.items.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('text.lines-per-page')}
                />
            ) : null}

            {showCrudButtons && (
                <Box pt={2} display='flex' justifyContent='space-between'>
                    <Box>
                        {canCreateLotItem && (
                            <LoadingButton
                                disabled={disableCreateLotItem}
                                className={classes.loadingButton}
                                onClick={handleClickCreateItem}
                                color='primary'
                                size='xsmall'
                                variant='outlined'
                                {...(creatingLotItem
                                    ? {
                                          loading: {
                                              text: `${t('info.creating-lot-item')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                <AddIcon />
                                &nbsp; {t('mkp.search.add-item')}
                            </LoadingButton>
                        )}
                        {canDeleteLotItems && (
                            <ConfirmDialog
                                title={t('process.components.message-confirm-delete-item')}
                                message={messageToConfirmDeleteItem}
                                onConfirm={handleDeleteItems}
                            >
                                <Button
                                    color='primary'
                                    size='xsmall'
                                    variant='outlined'
                                    disabled={selectedItems.length === 0}
                                >
                                    <span className={classes.termDelete}>{t('term.delete')}</span>
                                </Button>
                            </ConfirmDialog>
                        )}
                    </Box>
                    {/* 
                        @TODO: 11/06/2024 removemos o botão de salvamento individual, pois estava gerando bugs
                        {(canEditLot || canEditBidAmountDifference) && canCreateLotQuota && (
                        <LoadingButton
                            variant='contained'
                            onClick={() => onClickSaveLot()}
                            color='secondary'
                            size='xsmall'
                            title={t('process.components.save-lot-itens')}
                            disabled={!canSaveLot}
                            {...(savingLot
                                ? {
                                      loading: {
                                          text: `${t('term.saving')}..`,
                                      },
                                  }
                                : {})}
                        >
                            {hasQuota ? t('info.update-lot-quota') : t('info.create-lot-quota')}
                        </LoadingButton>
                    )}
                    {(canEditLot || canEditBidAmountDifference) &&
                        !canCreateLotQuota &&
                        lot.typeOfBenefit !== TypeOfBenefitValue.reservedQuota && (
                            <LoadingButton
                                variant='contained'
                                onClick={() => onClickSaveLot()}
                                color='primary'
                                size='xsmall'
                                title={
                                    !lot.id
                                        ? t('process.components.save-batch-before-itens')
                                        : t('process.components.save-lot-itens')
                                }
                                disabled={!canSaveLot}
                                {...(savingLot
                                    ? {
                                          loading: {
                                              text: `${t('term.saving')}..`,
                                          },
                                      }
                                    : {})}
                            >
                                {t('term.save')}
                            </LoadingButton>
                        )} */}
                </Box>
            )}
        </Box>
    );

    return (
        <Box mt={2}>
            <LoadingModal open={loading} message={t('info.wait-while-deleting-lot')} />
            <Grid component={Paper} variant='outlined' elevation={2} container>
                <Box className={classes.root}>
                    <ExpandedContentCard
                        omitExpandIcon
                        expanded={expandedLot}
                        header={
                            <ProcessLotHeader
                                visibleAdvancedInfoBlock={visibleAdvancedInfoBlock}
                                lotItem={lotItem}
                                lot={lot}
                                lotHasChanged={lotHasChanged}
                                expandedLot={expandedLot}
                                onExpandLot={setExpandedLot}
                                onClickAdvancedInfo={onClickAdvancedInfo}
                                onClickDeleteLot={onClickDeleteLot}
                            />
                        }
                        defaultExpanded={false}
                    >
                        {getCardContent()}
                    </ExpandedContentCard>
                </Box>
            </Grid>
        </Box>
    );
};

export default ProcessLotView;

import { useProcessLotsFormContext } from 'modules/process/context/process-lots-form.context';
import { FC } from 'react';
import { TypeOfBenefitValue } from 'clients/manager/interfaces/auction-notice-lot.interface';
import { AuctionType } from 'clients/manager/interfaces/auction-notice.interface';
import { QuotaTypeOfBenefitProps } from 'modules/process/interfaces/type-of-benefit';
import { useProcessFormContext } from '../../../../context/process-form.context';
import { processActions, processValidations } from '../../../../process-actions';
import TypeOfBenefit from '../../../type-benefit';

const QuotaTypeOfBenefit: FC<QuotaTypeOfBenefitProps> = ({
    lot,
    disabled,
    values,
    onClickUpdateLotQuota,
}) => {
    const { setFieldValue, processLotsForm } = useProcessLotsFormContext();
    const { processForm } = useProcessFormContext();
    const typeOfBenefitOmitOptions: TypeOfBenefitValue[] = [];
    const typeOfBenefitDisabledOptions: TypeOfBenefitValue[] = [];
    const hasQuota = lot.id !== null && processLotsForm.values.lots.find((formLot) => formLot.quotaId === lot.id);
    const isMasterQuota: boolean = lot.typeOfBenefit === TypeOfBenefitValue.masterQuota;
    const isMasterQuotaSaved: boolean = isMasterQuota && !!hasQuota;

    if (!processActions.visibleReservedQuotaOption(processForm?.values, lot)) {
        typeOfBenefitOmitOptions.push(TypeOfBenefitValue.reservedQuota);
    }

    if (!processActions.visibleMasterQuotaOption(processForm?.values)) {
        typeOfBenefitOmitOptions.push(TypeOfBenefitValue.masterQuota);
    }

    if (processValidations.isLotsFromImport(processForm?.values)) {
        typeOfBenefitDisabledOptions.push(TypeOfBenefitValue.masterQuota);
    }

    const disableTypeOfBenefit = processValidations.isLotsFromImport(processForm?.values) && isMasterQuota;

    const handleChange = (typeOfBenefit: TypeOfBenefitValue) => {
        if (isMasterQuotaSaved) onClickUpdateLotQuota(typeOfBenefit.toString());
        setFieldValue(lot, 'typeOfBenefit', typeOfBenefit);
    };

    return (
        <TypeOfBenefit
            omitOptions={typeOfBenefitOmitOptions}
            disabledOptions={typeOfBenefitDisabledOptions}
            disabled={disabled || disableTypeOfBenefit}
            defaultValue={values.typeOfBenefit}
            value={values.typeOfBenefit}
            onChange={(typeOfBenefit) => handleChange(typeOfBenefit)}
            auctionType={processForm?.values.auctionType as AuctionType}
            isMasterQuotaSaved={isMasterQuotaSaved}
        />
    );
};

export default QuotaTypeOfBenefit;

import { useContext } from 'react';
import { PlatformContext } from 'routes/platform.context';
import { getAuthToken } from 'modules/auth/session.utils';

export const getBackofficeRedirectUrl = (): string => {
    const { platform } = useContext(PlatformContext);
    if (!platform) {
        return '';
    }
    const urlLocation = window.location.toString();
    const { host } = new URL(urlLocation);
    const subdomain = host.split('.')[0];

    const fromToUrlPhp = {
        'app2-dev': 'app-dev',
        'app2-qa': 'app-qa',
        'app2-compras': 'app-compras',
        'app2-cemig': 'app-cemig',
        'app2x-dev': 'appx-dev',
        'app2x-qa': 'appx-qa',
        app2x: 'appx',
        app2: 'app',
        compras2: 'compras',
    };

    if (fromToUrlPhp[subdomain] === undefined) {
        if (host.includes('localhost') || host.includes('4000')) {
            return `http://licitardigital:8080`;
        }
        throw new Error('Application not found!');
    }

    const developmentTag = fromToUrlPhp[subdomain];

    return `https://${developmentTag}.${platform.domain}`;
};

const getAuthPhpPath = () => {
    const { token } = getAuthToken();

    return `/auth/?token=${token}&redirectUrl=`;
};

export const getBackofficeRedirectUrlPath = (): string => {
    const authPhpPath = getAuthPhpPath();
    const url = getBackofficeRedirectUrl();

    return `${url}${authPhpPath}`;
};

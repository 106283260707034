export enum ProcessRelatoryType {
    minuteDisputeRoom = 1,
    minuteOfProposals = 2,
    enableSuppliers = 3,
    adjudicationTerm = 4,
    homologationTerm = 5,
    minuteAccreditation = 6,
    minuteDesert = 7,
    minuteFrustrated = 8,
}

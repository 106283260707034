import { Link } from '@material-ui/core';
import { ProcessEnum } from 'clients/manager/interfaces/auction-notice.interface';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillFire, AiFillHeart } from 'react-icons/ai';
import { FaMoneyBillWave } from 'react-icons/fa';
import { IoArrowRedo } from 'react-icons/io5';
import { formatDate, formatDateDistance } from 'common/utils';
import ProcessStatusIcon from '../process-status-icon';
import ProcessTypeIcon from '../process-type-icon';
import {
    ProcessResponsiveBadgesContainer,
    ProcessSearchIds,
    ProcessSearchItemActionsContainer,
    ProcessSearchItemBottomOutBorder,
    ProcessSearchItemContainer,
    ProcessSearchItemContent,
    ProcessSearchItemDescription,
    ProcessSearchItemIconContainer,
    ProcessSearchItemLeftOutBorder,
    ProcessSearchItemStatusContainer,
} from './styled';

interface ProcessSearchItemProps {
    id: number;
    title: string;
    description?: string;
    auction?: string;
    accreditation?: string;
    startDate?: string;
    startDisputeDate?: string;
    endDate?: string;
    processUrl?: string;
    proposalUrl?: string;
    type: ProcessEnum[keyof ProcessEnum];
    status:
        | 'decision'
        | 'contract'
        | 'dispute'
        | 'finished'
        | 'published'
        | 'fail'
        | 'excluded'
        | 'canceled'
        | 'revoked'
        | 'annulled'
        | 'suspended';
    canPropose?: boolean;
    typeCancel?: string;
    isCanceled?: boolean;
    favorited?: boolean;
    suggested?: boolean;
    icon?: string;
}

const ProcessSearchItem = ({
    id,
    type,
    title,
    startDate,
    endDate,
    startDisputeDate,
    accreditation,
    processUrl,
    proposalUrl,
    description,
    status,
    canPropose,
    favorited,
    suggested,
    icon,
    auction,
}: ProcessSearchItemProps) => {
    const { t, i18n } = useTranslation();

    const statusColor = useMemo<string>(() => {
        const successStatus = ['finished', 'dispute', 'session', 'published'];
        const infoStatus = ['decision', 'contract'];
        const dangerStatus = ['excluded', 'canceled', 'revoked', 'annulled', 'fail'];

        if (successStatus.includes(status)) return 'var(--success-color)';
        if (infoStatus.includes(status)) return 'var(--secondary-color)';
        if (dangerStatus.includes(status)) return 'var(--danger-color)';
        return 'var(--warning-color)';
    }, [status]);

    return (
        <ProcessSearchItemContainer {...{ favorited, suggested }}>
            <ProcessSearchItemIconContainer {...{ favorited, suggested }}>
                <ProcessTypeIcon icon={type} />
                <h1>{t(`bidding.process.type.${type}`)}</h1>
                <span>{accreditation}</span>
                {(suggested || favorited) && (
                    <section>
                        {suggested && <AiFillFire />}
                        {favorited && <AiFillHeart />}
                        <ProcessSearchItemLeftOutBorder size={4} />
                        <ProcessSearchItemBottomOutBorder size={4} />
                    </section>
                )}
            </ProcessSearchItemIconContainer>
            <ProcessSearchItemContent>
                <ProcessSearchItemDescription>
                    <div>
                        {icon && <img src={icon} alt={title} />}
                        <ProcessResponsiveBadgesContainer>
                            <ProcessTypeIcon icon={type} size={18} />
                            <ProcessStatusIcon icon={status} size={18} />
                        </ProcessResponsiveBadgesContainer>
                        <h1>{title}</h1>
                    </div>
                    <h2>{description}</h2>
                    <ProcessSearchIds key={i18n.language}>
                        ID {id} | {auction} |{' '}
                        {startDate && endDate && type === ProcessEnum.C
                            ? t('bidding.search-item.date-period', {
                                  startDate: formatDate(startDate),
                                  endDate: formatDate(endDate),
                              })
                            : startDisputeDate &&
                              `${formatDate(startDisputeDate)} (${formatDateDistance(
                                  startDisputeDate
                              )})`}
                    </ProcessSearchIds>
                </ProcessSearchItemDescription>
                <ProcessSearchItemStatusContainer color={statusColor}>
                    <ProcessStatusIcon icon={status} color={statusColor} />
                    <h1>{t(`bidding.process.status.${status}`)}</h1>
                </ProcessSearchItemStatusContainer>
                <ProcessSearchItemActionsContainer {...{ favorited, suggested }}>
                    {processUrl && (
                        <Link href={processUrl}>
                            <IoArrowRedo />
                        </Link>
                    )}
                    {canPropose && proposalUrl && (
                        <Link href={proposalUrl}>
                            <FaMoneyBillWave />
                        </Link>
                    )}
                </ProcessSearchItemActionsContainer>
            </ProcessSearchItemContent>
        </ProcessSearchItemContainer>
    );
};

export default memo(ProcessSearchItem);

import { MenuItem } from '@material-ui/core';
import { TextField } from 'common/components';
import React, { FC, ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { JudgmentCriterion } from 'clients/manager/interfaces/auction-notice.interface';
import { disableField, processUtils } from 'modules/process/process-utils';
import { processActions } from 'modules/process/process-actions';
import { useProcessFormContext } from '../../../../context/process-form.context';
import HelpIconLabel from '../../../help-icon-label';

interface TypeValueBidFieldProps {
    handleChangeDefault: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const TypeValueBidField: FC<TypeValueBidFieldProps> = ({ handleChangeDefault }) => {
    const { processForm, process } = useProcessFormContext();
    const { t } = useTranslation();

    const typeValueBid = useMemo(() => {
        const opts: ReactNode[] = [<MenuItem value='unit'>{t('term.unitary')}</MenuItem>];

        if (
            processForm?.values.judgmentCriterion !== JudgmentCriterion.highestDiscount &&
            !(
                processForm?.values.judgmentCriterion === JudgmentCriterion.lowestPrice &&
                processForm?.values.rateTypeBid === 1
            )
        ) {
            opts.push(<MenuItem value='kit'>Global</MenuItem>);
        }
        return opts;
    }, [processForm?.values.judgmentCriterion, processForm?.values.rateTypeBid]);

    return (
        <HelpIconLabel
            title={
                <>
                    <p>
                        <b>{t('term.unitary')}: </b>
                        {t('info.unitary-field')}
                    </p>
                    <p>
                        <b>Global: </b>
                        {t('info.global-field')}
                    </p>
                </>
            }
        >
            <TextField
                fullWidth
                color='primary'
                label={t('term.bid-format')}
                onChange={handleChangeDefault}
                variant='outlined'
                name='typeValueBid'
                select
                value={processForm?.values.typeValueBid}
                defaultValue={processForm?.values.typeValueBid}
                disabled={disableField('typeValueBid', process)}
            >
                {typeValueBid}
            </TextField>
        </HelpIconLabel>
    );
};

export default TypeValueBidField;
